import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import '../styles/log-in.css';
import { callMsGraph } from '../services/graph';
import { useMsal } from '@azure/msal-react';
import peoplEx from '../images/Capa_1.png';
import LoginComponent from './loginComponent';

//MSAL
import { loginRequest } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';


const Login = ({ setLoggedIn, setUserInfoSSFF, userInfoSSFF }) => {
  const [showLoginComponent, setShowLoginComponent] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [legalPopup, setLegalPopup] = useState(true);
  const [cookies, setCookie] = useCookies(['acceptedPrivacyPolicy_0624']);

  const handleAcceptPrivacyPolicy = () => {
    setCookie('acceptedPrivacyPolicy_0624', 'true', { path: '/' });
    setLegalPopup(false);
  };

  const handleLoginPromise = () => {
    return new Promise((resolve, reject) => {
      instance
        .loginPopup(loginRequest)
        .then(() => {
          resolve(true); // Resolvemos la promesa con true para indicar que el inicio de sesión fue exitoso
        })
        .catch((error) => {
          console.error('Error en el inicio de sesión:', error);
          resolve(false); // Resolvemos la promesa con false para indicar que el inicio de sesión falló
        });
    });
  };

  const isProduction = true; // En ejeccución DEV es false

  const handleLogin = async () => {
    if (isProduction) {
      try {
        const loginResponse = await instance.loginPopup(loginRequest); 
        if (loginResponse) {
        
          setLegalPopup(false);
          navigate('/home');
        } else {
          console.log('Inicio de sesión fallido');
        }
      } catch (error) {
        console.error('Error durante el login:', error);
      }
    } else {
      setShowLoginComponent(true);
    }
  };

  const showLegalPopup = !cookies.acceptedPrivacyPolicy_0624 && legalPopup;

  const handleAcceptAndLogin = async () => {
    handleAcceptPrivacyPolicy(); // Esta es sincrónica pero configura el estado y las cookies
    await handleLogin(); // Espera a que esta asincrónica complete
  };

  return (
    <>
      <section className="login">
        {/* Sombra del login */}
        <div
          className={showLegalPopup ? 'login-shade active' : 'login-shade'}
        />
        {/* Pop up legal */}
        {showLegalPopup && (
          <div className="legal-popup active">
            {/* Contenido del aviso legal */}
            <h1 className="legal__title">
              AVISO LEGAL DE PROTECCIÓN DE DATOS PERSONALES
            </h1>
            <p className="legal__desc">
              La entidad jurídica que es tu empleadora y que tiene relación con
              el Grupo Telefónica:
              <ul>
                <li>
                  TELEFÓNICA, S.A. domiciliada en Madrid, Gran Vía número 28.
                </li>
                <li>
                  TELEFÓNICA GLOBAL SOLUTIONS SL (UNIPERSONAL) domiciliada en
                  Madrid, Ronda de la Comunicación s/n Edificio Oeste 1.
                </li>
                <li>
                  {' '}
                  TELEFÓNICA INNOVACIÓN DIGITAL SL (UNIPERSONAL) domiciliada en
                  Madrid, Ronda de la Comunicación s/n
                </li>
                <li>
                  TELXIUS CABLE ESPAÑA, S.L. domiciliada en Madrid, Ronda de la
                  Comunicación s/n.
                </li>
                <li>
                  TELXIUS TELECOM, S.A. domiciliada en Madrid, Ronda de la
                  Comunicación s/n{' '}
                </li>
                <li>
                  TELEFÓNICA COMPRAS ELECTRÓNICAS, S.L.U. domiciliada en Madrid,
                  Gran Vía número 28.
                </li>
                <li>
                  TELEFÓNICA OPEN INNOVATION Ronda de la Comunicación s/n 28050
                  Madrid.
                </li>
                <li>
                  {' '}
                  TELEFÓNICA HISPAM: Ronda de la Comunicación, s/n .28050 Madrid
                </li>
              </ul>
              (en adelante, cada una de ellas, "Telefónica"), te informa que es
              la responsable del tratamiento de los datos de carácter personal
              que son tratados a través del servicio “PeoplEX”. A través de este
              servicio, el empleado del Grupo Telefónica, en forma de
              autoservicio, podrá conocer la respuesta a diferentes dudas que
              tenga en relación con su entorno de trabajo y/o en el contexto en
              el que desarrolla sus funciones profesionales, así como, mediante
              un sistema de chat con un agente autorizado por Telefónica, podrá
              presentar una solicitud, duda, o incidencia en relación con dicho
              entorno o contexto. El empleado, de forma voluntaria, o a
              solicitud del agente autorizado por ser necesario para tramitar la
              solicitud, duda, o incidencia, facilitará aquellos datos
              personales que resulten necesarios. Estos datos personales será
              tratados por Telefónica a través del servicio con la finalidad de
              atender la solicitud, duda o incidencia planteada; bien en base a
              la gestión y ejecución del contrato laboral entre el empleado y
              Telefónica, por resultar necesario para tramitar una solicitud,
              duda o incidencia que tiene relación con la gestión y ejecución
              del contrato laboral como, por ejemplo, realizar un cambio de la
              cuenta bancaria donde se percibe la nómina o un cambio en las
              condiciones personales que afectan al cálculo de la nómina; o bien
              en base al consentimiento, libremente otorgado por el empleado,
              cuando presenta una solicitud, duda o incidencia y facilita, o es
              necesario que facilite, determinadas tipologías de datos
              personales, incluyendo aquellos de categoría especial, que
              únicamente se pueden tratar con la voluntad del interesado como,
              por ejemplo, una solicitud con relación a una baja por enfermedad
              en la que el empleado facilita voluntariamente determinados datos
              de salud. El empleado tendrá derecho a retirar su consentimiento
              en cualquier momento y la retirada del mismo no afectará a la
              licitud del tratamiento basada en el consentimiento previo a este
              momento. La determinación de una base legitimadora en concreto se
              realizará en función de la tipología de solicitud, duda o
              incidencia planteada. Para llevar a cabo la finalidad de atender
              la solicitud, duda o incidencia del empleado a través del
              servicio, el agente autorizado podrá, bien dar una respuesta en
              ese mismo momento, en cuyo caso se genera un “ticket” de
              resolución de solicitud, duda o incidencia, o bien, en el caso de
              que plantee mayor complejidad o necesite recabarse información
              adicional, se generará un “ticket” de apertura de la solicitud,
              duda o incidencia, para ser tramitada y resuelta en un acto
              posterior por el departamento de atención al empleado competente
              de Telefónica. Los datos personales facilitados por el empleado se
              retendrán por Telefónica por el tiempo estrictamente necesario
              para cumplir con la finalidad informada y, una vez generado el
              “ticket” de resolución de solicitud, duda o incidencia, durante el
              plazo de 5 años para, una vez superado ese plazo, ser borrados y/o
              debidamente bloqueados a disposición de las autoridades
              competentes y durante los plazos de prescripción de las acciones
              que pudieran derivarse por el tratamiento de datos personales
              realizado. Los datos personales facilitados por el empleado podrán
              ser comunicados a otras entidades del Grupo Telefónica cuando
              resulte necesario para cumplir con la finalidad informada y con
              base a la ejecución del contrato y consentimiento citados.
              Asimismo, podrán ser comunicados a las autoridades competentes
              cuando exista una norma o requerimiento legal que así lo exija. No
              se prevé transferir dichos datos personales a entidades que
              residan en países fuera del Espacio Económico Europeo, salvo a
              aquellos terceros que prestan un servicio necesario para cumplir
              con la finalidad informada y que se encuentran bajo las
              instrucciones de Telefónica a través de un acuerdo de tratamiento
              de datos personales. Además, Telefónica también podrá transferir
              los datos personales a aquellas entidades del Grupo Telefónica
              residentes en un tercer país, siempre que dicha transferencia sea
              necesaria para cumplir con la finalidad informada. En todo caso,
              estas transferencias se realizan con los debidos mecanismos
              legales y tomando las debidas garantías para la protección de los
              datos personales. El empleado podrá conocer qué datos personales
              trata Telefónica a través del servicio, retirar su consentimiento,
              así como ejercitar el resto de sus derechos de conformidad a la
              normativa aplicable escribiendo a:{' '}
              <a href="preguntanos@personas.telefonica.com ">
                preguntanos@personas.telefonica.com{' '}
              </a>
              sobre el tratamiento de sus datos personales a través del
              servicio, puede ponerse en contacto con la Oficinal Global del
              delegado de Protección de Datos Personales de Telefónica, S.A.,
              escribiendo a{' '}
              <a href="mailto:DPO_telefonicasa@telefonica.com. ">
                DPO_telefonicasa@telefonica.com.{' '}
              </a>
              Podrá, asimismo, presentar una reclamación ante la autoridad
              competente de protección de datos personales (en España, la
              Agencia Española de Protección de Datos) si considera que no se
              han satisfecho sus derechos. En el caso de que el empleado
              facilite datos personales de otros interesados al presentar su
              solicitud, duda o incidencia, como, por ejemplo, datos personales
              relativos a sus familiares, declara y garantiza que ha informado a
              dichos interesados del tratamiento de sus datos personales por
              Telefónica, de conformidad al presente Aviso Legal, así como, en
              el caso de resultar necesario, haber obtenido su autorización para
              facilitar sus datos personales a Telefónica, manteniendo indemne a
              esta entidad ante cualquier daño o responsabilidad derivado del
              incumplimiento de lo anterior.
              <br></br>
              Al descargar un certificado en PeoplEx, la persona trabajadora,
              reconoce y acepta que el uso de este certificado no otorga ni
              implica ningún derecho adquirido, condición beneficiosa,
              privilegio, o beneficio adicional, más allá de los ya establecidos
              en su contrato de trabajo y las políticas de la empresa. Este
              certificado se proporciona únicamente con fines informativos y no
              debe interpretarse como un compromiso contractual adicional por
              parte de la empresa ante el empleado u otro organismo publico o
              privado. Cualquier error o mal uso del certificado no será
              responsabilidad de la empresa. Es importante revisar detenidamente
              el contenido del mismo antes de su uso y, en caso de encontrar
              algún error, notificarlo al “área de Personas” para su corrección,
              a través del botón “Contacta con nosotros” de PeoplEX.
              <br></br>
              Al hacer clic en Aceptar, usted confirma que ha leído y entendido
              estos terminos.
            </p>
            <div className="legalbutton__section">
              <button className="legalbutton" onClick={handleAcceptAndLogin}>
                Aceptar
              </button>
            </div>
          </div>
        )}
        <div className="login__text">
          <img src={peoplEx} alt="peoplEx-icon" className="peoplEx-icon" />
          <h1 className="login__desc">
            Encuentra información o documentación y resuelve tus dudas en una
            plataforma global
          </h1>
          {/* <Link to="/home" className="login__button">
         INICIAR SESIÓN
        </Link> */}
          <a onClick={handleLogin} className="login__button">
            INICIAR SESIÓN
          </a>
        </div>
        {showLoginComponent && (
          <LoginComponent setUserInfoSSFF={setUserInfoSSFF} />
        )}
      </section>
    </>
  );
};

export default Login;
