import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import '../../styles/form.css';
import {
  attachMedia,
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../../services/api';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../../utils/utils';

const TelematicCert = ({ userInfoSSFF }) => {
  const [files, setFiles] = useState([]);
  const [nombreApellidosRecienNacido, setNombreApellidosRecienNacido] =
    useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [permisoMatPatInicio, setPermisoMatPatInicio] = useState('');
  const [permisoMatPatFin, setPermisoMatPatFin] = useState('');
  const [beneficiario, setBeneficiario] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showFilesRequired, setShowFilesRequired] = useState(false);
  const [
    showNombreApellidosRecienNacidoRequired,
    setshowNombreApellidosRecienNacidoRequired,
  ] = useState(false);
  const [showFechaNacimientoRequired, setShowFechaNacimientoRequired] =
    useState(false);
  const [showPermisoMatPatInicioRequired, setShowPermisoMatPatInicioRequired] =
    useState(false);
  const [showPermisoMatPatFinRequired, setShowPermisoMatPatFinRequired] =
    useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [selectedFilesInfo, setSelectedFilesInfo] = useState([]);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Forzar el scroll a la parte superior cuando el componente se monta
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const areFieldsValid =
      files !== null &&
      nombreApellidosRecienNacido.trim() !== '' &&
      fechaNacimiento.trim() !== '' &&
      permisoMatPatInicio.trim() !== '' &&
      permisoMatPatFin.trim() !== '';
    setIsButtonEnabled(areFieldsValid);

    const filesInfo = files.map((file) => ({
      name: file.name,
      extension: file.name.split('.').pop(),
    }));
    setSelectedFilesInfo(filesInfo);
  }, [
    files,
    nombreApellidosRecienNacido,
    fechaNacimiento,
    permisoMatPatInicio,
    permisoMatPatFin,
    beneficiario,
  ]);

  const handleNombreApellidosRecienNacidoChange = (e) => {
    setNombreApellidosRecienNacido(e.target.value);
    setshowNombreApellidosRecienNacidoRequired(false);
  };

  const handleFechaNacimientoChange = (e) => {
    setFechaNacimiento(e.target.value);
    setShowFechaNacimientoRequired(false);
  };

  const handlePermisoMatPatInicioChange = (e) => {
    setPermisoMatPatInicio(e.target.value);
    setShowPermisoMatPatInicioRequired(false);
  };

  const handlePermisoMatPatFinChange = (e) => {
    setPermisoMatPatFin(e.target.value);
    setShowPermisoMatPatFinRequired(false);
  };

  const handleBeneficiarioChange = (e) => {
    setBeneficiario(!beneficiario);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split('.').pop())) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setShowFormatNotSupported(false);
    } else {
      setShowFormatNotSupported(true);
    }

    if (files.length >= 0) {
      setShowFilesRequired(false);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid =
      files !== null &&
      nombreApellidosRecienNacido.trim() !== '' &&
      fechaNacimiento.trim() !== '' &&
      permisoMatPatInicio.trim() !== '' &&
      permisoMatPatFin.trim() !== '';
    if (nombreApellidosRecienNacido === '') {
      setshowNombreApellidosRecienNacidoRequired(true);
    }
    if (fechaNacimiento === '') {
      setShowFechaNacimientoRequired(true);
    }
    if (files.length === 0) {
      setShowFilesRequired(true);
    }
    if (permisoMatPatInicio === '') {
      setShowPermisoMatPatInicioRequired(true);
    }
    if (permisoMatPatFin === '') {
      setShowPermisoMatPatFinRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        // Sanitizar los valores de entrada del formulario
        const sanitizedNombreApellidosRecienNacido = DOMpurify.sanitize(
          nombreApellidosRecienNacido
        );
        const sanitizedFechaNacimiento = DOMpurify.sanitize(fechaNacimiento);
        const sanitizedPermisoMatPatInicio =
          DOMpurify.sanitize(permisoMatPatInicio);
        const sanitizedPermisoMatPatFin = DOMpurify.sanitize(permisoMatPatFin);
        const sanitizedBeneficiario = DOMpurify.sanitize(beneficiario);

        const message = `
      <div style={{ backgroundColor: 'transparent' }}>
      <div><br /></div>
      1. EMAIL CORPORATIVO
      <br />
      <b>${userInfoSSFF.username}</b>
      </div>
      <div><br /></div>
       <div>
        2. NOMBRE Y APELLIDOS
         <br />
         <b>${dataUserInbenta.data[0].name}</b>
          </div>
      <div><br /></div>
      <div>
      4. EMPRESA
      <br />
      <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
      <div><br /></div>
      4. NOMBRE Y APELLIDOS DEL RECIÉN NACIDO
      <br />
      <b>${sanitizedNombreApellidosRecienNacido}</b>
      <div><br /></div>
      5. FECHA DE NACIMIENTO
      <br />
      <b>${sanitizedFechaNacimiento}</b>
      <div><br /></div>
      6. PERMISO DE PATERNIDAD REGISTRADO EN SSFF
      <br />
      INICIO
      <br />
      <b>${sanitizedPermisoMatPatInicio}</b>
      <br />
      FIN
      <br />
      <b>${sanitizedPermisoMatPatFin}</b>
      <div><br /></div>
      7. DESEAS INCLUIR A TU HIJO/HIJA, COMO BENEFICIARIO/A DEL SEGURO MÉDICO.
      <br />
      <b>${sanitizedBeneficiario}</b>
      <div><br /></div>
    </div>
      `;

        let uuidsMediaArray = [];
        await Promise.all(
          files.map(async (file) => {
            const base64 = await readFileAsBase64(file);
            const uuidMedia = await attachMedia(file.name, base64);
            uuidsMediaArray.push(uuidMedia);
          })
        );

        await createTicket(
          'Solicitud del Certificado Telemático de Empresa (maternidad/paternidad)',
          message,
          userInfoSSFF.custom03,
          1,
          uuidsMediaArray,
          dataUserInbenta.data[0].id
        );

        setNombreApellidosRecienNacido('');
        setFechaNacimiento('');
        setFiles([]);
        setPermisoMatPatInicio('');
        setPermisoMatPatFin('');
        setBeneficiario('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };
  return (
    <>
      <section className="formArticle">
        <h1 className="formArticle__title">
          Solicitud del Certificado Telemático de Empresa
          (maternidad/paternidad)
        </h1>
        <p className="formArticle__text">
          Por favor, rellena este formulario para que podamos generar el
          Certificado Telemático de Empresa y enviarlo a la Seguridad Social.
          (Te recordamos que es necesario que hayas registrado previamente tu
          permiso de maternidad/paternidad en SSFF, y que hayas actualizado la
          información de tus dependientes). Una vez enviemos el certificado a la
          Seguridad Social, te lo haremos saber para que puedas iniciar los
          trámites de solicitud de tu prestación, siguiendo los pasos que se
          detallan en PeoplEx.{' '}
        </p>
        <form
          target="_blank"
          className="formArticle__form"
          action="#"
          onSubmit={handleSubmit}
        >
          <div className="formArticleSection">
            <label htmlFor="formArticle__option" className="formArticleLabel">
              Justificante del registro
              {showFilesRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
              {showFormatNotSupported && (
                <p className="formArticleLabel__required">
                  Formato de archivos no soportado. Utilice:{' '}
                  {ALLOWED_TYPE_FILES.join(', ')}
                </p>
              )}
            </label>
            <p className="formArticle__text">
              Adjunta copia del registro de tu hijo/a en el Registro Civil o el
              informe de maternidad/paternidad que te darán en el hospital
            </p>
            <div className="formButtonsContainer">
              <label for="files" className="formArticleButton secondaryButton">
                Seleccionar archivos
              </label>
              <input
                type="file"
                id="files"
                onChange={handleFileChange}
                multiple
              />
            </div>
          </div>
          <div className="formArticleSection">
            <label
              className="formArticleLabel"
              htmlFor="nombreApellidosRecienNacido"
            >
              Nombre y Apellidos del recién nacido
              {showNombreApellidosRecienNacidoRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={nombreApellidosRecienNacido}
              onChange={handleNombreApellidosRecienNacidoChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="fechaNacimiento">
              Fecha de nacimiento
              {showFechaNacimientoRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="date"
              id="fechaNacimiento"
              className="formArticleInputText form-date__input"
              value={fechaNacimiento}
              onChange={handleFechaNacimientoChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="permisoMatPat">
              Permiso de maternidad/paternidad registrado en SSFF
            </label>
            <div className="datesContainer">
              <div className="datesContainer--date">
                <p className="formArticle__contact">
                  Inicio
                  {showPermisoMatPatInicioRequired && (
                    <p className="formArticleLabel__required">
                      Este es un campo requerido
                    </p>
                  )}
                </p>
                <input
                  type="date"
                  id="inicio"
                  className="formArticleInputText form-date__input"
                  value={permisoMatPatInicio}
                  onChange={handlePermisoMatPatInicioChange}
                />
              </div>
              <div className="datesContainer--date">
                <p className="formArticle__contact">
                  Fin
                  {showPermisoMatPatFinRequired && (
                    <p className="formArticleLabel__required">
                      Este es un campo requerido
                    </p>
                  )}
                </p>
                <input
                  type="date"
                  id="fin"
                  className="formArticleInputText form-date__input"
                  value={permisoMatPatFin}
                  onChange={handlePermisoMatPatFinChange}
                />
              </div>
            </div>
          </div>
          <div className="formArticleSection">
            <p className="formArticle__text">
              (Nota: Recuerda que el permiso de maternidad/paternidad debe ser
              de al menos 6 semanas, y como máximo de 16, dentro de los 12 meses
              siguientes al parto o bien de la resolución judicial o
              administrativa en caso de adopción).
            </p>
          </div>
          <div className="formArticleSection">
            <label htmlFor="beneficiario">
              <input
                type="checkbox"
                name="beneficiario"
                className="formArticleInputText"
                value={beneficiario}
                onChange={handleBeneficiarioChange}
              />{' '}
              Por favor, marca este recuadro si deseas incluir a tu hijo/hija,
              como beneficiario/a del seguro médico.
            </label>
          </div>
          <div className="formArticleButton__section">
            <button
              className={`formArticleButton ${
                isButtonEnabled ? 'enabled' : 'disabled'
              }`}
              type="submit"
            >
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class="loader"></div>
            )}
          </div>
          {selectedFilesInfo.map((fileInfo, index) => (
            <div key={index} className="selectedFileContainer">
              <p className="formArticle__fileInfo">{`${fileInfo.name}`}</p>
              <div className="removeFileIconContainer">
                <svg
                  onClick={() => removeFile(index)}
                  xmlns="http://www.w3.org/2000/svg"
                  // width="16"
                  // height="16"
                  fill="currentColor"
                  className="removeFileIcon"
                  viewBox="0 0 40 40"
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33.503 0.496584C34.1657 1.15869 34.1657 2.23898 33.503 2.90109L19.3891 17.0029L33.503 31.1163C34.154 31.7901 34.1424 32.8703 33.4565 33.5208C32.7938 34.1597 31.7475 34.1597 31.0964 33.5208L16.9826 19.4074L2.90357 33.4744C2.2409 34.1365 1.15969 34.1365 0.497008 33.4744C-0.165669 32.8123 -0.165669 31.7436 0.497008 31.0699L14.576 17.0029L0.497008 2.94756C-0.165669 2.28544 -0.165669 1.21677 0.497008 0.543047C1.15969 -0.119064 2.22927 -0.119064 2.90357 0.543047L16.9826 14.61L31.1081 0.496584C31.7707 -0.165528 32.8403 -0.165528 33.503 0.496584Z"
                      fill="#E66C64"
                    />
                  </svg>
                </svg>
              </div>
            </div>
          ))}
        </form>
        <div className="contactTextContainer">
          <p className="formArticle__contact">
            Para cualquier duda, contacta al buzón{' '}
            <a href="mailto:preguntanos@personas.telefonica.com">
              preguntanos@personas.telefonica.com
            </a>
          </p>
        </div>
        {feedbackPopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content failurePopup">
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button failureButton"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TelematicCert;
