import React, { useState, useEffect,useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// Importa los componentes necesarios
import ProtectedRoutes from './knowledgePortal/components/protectedRoutes';
import Header from './components/header';
import FeaturedContent from './knowledgePortal/components/featured_content';
import FrequentQuestions from './knowledgePortal/components/frequent_questions';
import Searcher from './knowledgePortal/components/Searcher';
import BubblesCategories from './knowledgePortal/components/bubbles_categories';
import Subcategories from './knowledgePortal/components/subcategories';
import SubcategoriesArticleList from './knowledgePortal/components/subcategories_article-list';
import About from './knowledgePortal/components/about';
import HelpUs from './knowledgePortal/components/help_us';
import TimeOutPopUp from './components/timeOut_popup';
import SearchArticleList from './knowledgePortal/components/search_article-list';
import Footer from './components/footer';
import ContactUs from './knowledgePortal/components/contactUs-button';
import NotFound404 from './knowledgePortal/components/notFound404';
// import ContactPage from './components/contactUs-page';
import AboutUsPage from './knowledgePortal/components/aboutUsPage';
import Roles from './knowledgePortal/components/roles';
import RolesGroups from './knowledgePortal/components/rolesGroups';
import FeaturedContentItem from './knowledgePortal/components/featured_content-item';
// Importa los componentes de formularios
import Loan from './knowledgePortal/components/forms/loan';
import Fusion from './knowledgePortal/components/forms/fusion';
import HealthInsurance from './knowledgePortal/components/forms/healthInsurance';
import LifeInsurance from './knowledgePortal/components/forms/lifeInsurance';
import Advance from './knowledgePortal/components/forms/advance';
import SharedPayment from './knowledgePortal/components/forms/sharedPayment';
import HealthInsuranceBeneficiaries from './knowledgePortal/components/forms/healthInsuranceBeneficiaries';
import LifeInsuranceBeneficiaries from './knowledgePortal/components/forms/lifeInsuranceBeneficiaries';
import TelematicCert from './knowledgePortal/components/forms/telematicCert';
import TelematicCertPeriods from './knowledgePortal/components/forms/telematicCertPeriods';
import Incidence from './knowledgePortal/components/forms/Incidence';
// import NewFeaturePopup from './components/newFeature';
import MyFolderPage from './folder/components/myFolderPage';
import MyFolderSection from './folder/components/myFolderSection';

// Importa las funciones de API necesarias
import {
  fetchCategories,
  fetchFeaturedContent,
  fetchfrequentQuestions,
  handleTokenRefresh,
  fetchAutocompleter,
  fetchExtraInfoRoles,
  fetchExtraInfoFeatContent,
  fetchCategoriesDescription,
} from './services/api';

// Importa los estilos
import './styles/app.css';
import './styles/vars.css';
import AuthTeamsOrMsal from './authTeamsOrMsal';
import { TeamsFxContext } from './Context';

function App(props) {
  const [filterInSearcher, setFilterInSearcher] = useState([]);
  const handleFilterSearcher = (value) => {
    setFilterInSearcher(value);
  };
  const [userInfoSSFF, setUserInfoSSFF] = useState({});
  // Variables estado
  const [loggedIn, setLoggedIn] = useState(false);
  const [categories, setCategories] = useState([]); // Variable estado para almacenar las 'Categorias' de la API
  const [selectedCategory, setSelectedCategory] = useState([]); // Variable estado que almacena el ID de la Categoría sobre la que clickamos
  const [selectedSubcategory, setSelectedSubcategory] = useState([]); // Variable estado que almacena el ID de la Subcategoría sobre la que clickamos
  const [frequentQuestions, setFrequentQuestions] = useState([]); // Variable estado para almacenar las 'Preguntas Frecuentes' de la API
  const [featuredContent, setFeaturedContent] = useState([]); // Variable estado para almacenar el 'Contenido Destacado' de la API
  const [dataFeaturedContent, setDataFeaturedContent] = useState([]); // Variable estado para almacenar los datos del artículo de 'Contenido Destacado' de la API
  const [autocompleteArticles, setAutocompleteArticles] = useState([]); // Variable estado para almacenar en Local Storage los artículos del autocomplete
  const [isInactive, setIsInactive] = useState(false); // Variable estado para almacenar el estado de apertura y cierre del artículo
  const [handleUpperCategoryIdParam, setHandleUpperCategoryIdParam] =
    useState(''); // Variable estado con la que pasaremos como parámetro el upperCategoryIdParam de Inbenta (BusinessEX-40/ PeoplEX-133/ Contenidos Destacados-134/ Roles-138)
  const [extraInfoRoles, setExtraInfoRoles] = useState([]); // Variable estado para guardar el campo 'extra info Roles' de la API
  const [extraInfoFeatContent, setExtraInfoFeatContent] = useState([]); // Variable estado para guardar el campo 'extra info Contenido Destacado' de la API
  const [selectedRole, setSelectedRole] = useState({}); // Variable estado para guardar el 'group' sobre el que se ha clickado paraa mostrar las familias y roles.
  const [extraInfoDescriptionCategories, setExtraInfoDescriptionCategories] =
    useState([]); // Variable estado para guardar el campo 'descripcion categorias' de la API
  const [isPageGroup, setIsPageGroup] = useState(false); // Variable estado que usamos para reutilizar el componente de article-list y modificar el 'breadcrumb' en abse a si el artículo es de 'roles' o no.
  // const [featurePopup, setFeaturePopup] = useState(true);
  // const [cookies, setCookie] = useCookies(['newFeaturePopup']);
  const [loggedInDisplayName, setLoggedInDisplayName] = useState(null);
  const { themeString } = useContext(TeamsFxContext); // themeString nos sirve para obtener el theme de Teams Fx

  // Función para manejar la inactividad

  const handleInactivity = () => {
    setIsInactive(true);
  };

  useEffect(() => {
    let inactivityTimer;
    const resetInactivity = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      inactivityTimer = setTimeout(handleInactivity, 300000); // 5 minutos
    };

    window.addEventListener('mousemove', resetInactivity);
    window.addEventListener('keydown', resetInactivity);

    return () => {
      window.removeEventListener('mousemove', resetInactivity);
      window.removeEventListener('keydown', resetInactivity);
    };
  }, []);

  useEffect(() => {
    const fetchSearcherData = async () => {
      try {
        if (!localStorage.getItem('autocompleteData')) {
          const autocompleteArticlesData = await fetchAutocompleter();
          localStorage.setItem(
            'autocompleteData',
            JSON.stringify(autocompleteArticlesData)
          );
          setAutocompleteArticles(autocompleteArticlesData);
        } else {
          setAutocompleteArticles(
            JSON.parse(localStorage.getItem('autocompleteData'))
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchSearcherData();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      if (userInfoSSFF.custom03 !== undefined) {
        try {
          const [
            categoriesData,
            extraInfoRolesData,
            extraInfoDescriptionCategoriesData,
            extraInfoFeatContentData,
            featuredContentData,
            frequentQuestionsData,
          ] = await Promise.all([
            fetchCategories(),
            fetchExtraInfoRoles(),
            fetchCategoriesDescription(),
            fetchExtraInfoFeatContent(userInfoSSFF.custom03),
            fetchFeaturedContent(userInfoSSFF.custom03),
            fetchfrequentQuestions(userInfoSSFF.custom03),
          ]);

          setCategories(categoriesData);
          setExtraInfoRoles(extraInfoRolesData);
          setExtraInfoDescriptionCategories(extraInfoDescriptionCategoriesData);
          setExtraInfoFeatContent(extraInfoFeatContentData);
          setFeaturedContent(featuredContentData);
          setFrequentQuestions(frequentQuestionsData);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchApiData();
  }, [userInfoSSFF]);

  // useEffect de la redirección de 'contacto' a bubbles-categories'
  const location = useLocation();

  useEffect(() => {
    // Verificar si la URL incluye un hash
    if (location.hash === '#bubbles-categories') {
      // Desplazar la página al componente BubblesCategories
      const element = document.getElementById('bubbles-categories');
      if (element) {
        element.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, [location]);

  
  /* useEffect(() => {
    if (themeString === 'dark') {
      const textElements = document.querySelectorAll('.text-color');
      
      textElements.forEach(el => {
        el.style.color = '#ffffff !important'; 
      });
    } 
  }, [themeString]); */
  
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound404 />} />
        <Route
          path="/"
          element={
            <>
              {/* <LoginComponent
                setUserInfoSSFF={setUserInfoSSFF}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              /> */}
              <AuthTeamsOrMsal
                isInTeams={props.isInTeams}
                setUserInfoSSFF={setUserInfoSSFF}
              />
            </>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoutes
              setUserInfoSSFF={setUserInfoSSFF}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              setLoggedInDisplayName={setLoggedInDisplayName}
            />
          }
        >
          <Route
            path="home"
            element={
              loggedIn && (
                <>
                  {/* {showNewFeaturePopup && (
                    <NewFeaturePopup onClose={handleClosePopup} />
                  )} */}
                  {!props.isInTeams && (
                    <TimeOutPopUp
                      isInactive={isInactive}
                      setIsInactive={setIsInactive}
                      handleTokenRefresh={handleTokenRefresh}
                    />
                  )}
                  <Header
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={
                      setHandleUpperCategoryIdParam
                    }
                    userInfoSSFF={userInfoSSFF}
                  />
                  <Searcher
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    autocompleteArticles={autocompleteArticles}
                    setHandleUpperCategoryIdParam={
                      setHandleUpperCategoryIdParam
                    }
                    userInfoSSFF={userInfoSSFF}
                    loggedInDisplayName={loggedInDisplayName}
                    isInTeams={props.isInTeams}
                  />
                  <div className="container">
                    <BubblesCategories
                      userInfoSSFF={userInfoSSFF}
                      categories={categories}
                      setSelectedCategory={setSelectedCategory}
                      extraInfoDescriptionCategories={
                        extraInfoDescriptionCategories
                      }
                    />
                    <FeaturedContent
                      categories={categories}
                      featuredContent={featuredContent}
                      setDataFeaturedContent={setDataFeaturedContent}
                      extraInfoFeatContent={extraInfoFeatContent}
                      userInfoSSFF={userInfoSSFF}
                    />
                    <FrequentQuestions
                      frequentQuestions={frequentQuestions}
                      userInfoSSFF={userInfoSSFF}
                    />
                    <About />
                    <HelpUs />
                    <Footer />
                  </div>
                  <ContactUs userInfoSSFF={userInfoSSFF} />
                </>
              )
            }
          />
          <Route
            path="subcategories"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Subcategories
                    subcategories={categories}
                    selectedCategory={selectedCategory}
                    setIsPageGroup={setIsPageGroup}
                    setSelectedSubcategory={setSelectedSubcategory}
                    extraInfoDescriptionCategories={
                      extraInfoDescriptionCategories
                    }
                    setExtraInfoDescriptionCategories={
                      setExtraInfoDescriptionCategories
                    }
                  />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <SubcategoriesArticleList
                    selectedSubcategory={selectedSubcategory}
                    isPageGroup={isPageGroup}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/advance"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Advance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/fusion"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Fusion userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/healthinsurance"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <HealthInsurance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/healthinsurancebeneficiaries"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <HealthInsuranceBeneficiaries userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/incidence"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Incidence userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/lifeinsurance"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <LifeInsurance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/lifeinsurancebeneficiaries"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <LifeInsuranceBeneficiaries userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/loan"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Loan userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/sharedpayment"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <SharedPayment userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/telematic"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <TelematicCert userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="subcategories/articles/form/telematicperiods"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <TelematicCertPeriods userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path="search/articles"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <SearchArticleList
                    filterInSearcher={filterInSearcher}
                    handleUpperCategoryIdParam={handleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          {/* <Route
          path="/contacto"
          element={
            <div className="container">
              <ContactPage />
            </div>
          }
        /> */}
          <Route
            path="aboutUs"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <AboutUsPage />
                </div>
              </>
            }
          />
          <Route
            path="roles"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <Roles
                    categories={categories}
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={
                      setHandleUpperCategoryIdParam
                    }
                    setSelectedRole={setSelectedRole}
                    setSelectedSubcategory={setSelectedSubcategory}
                    setIsPageGroup={setIsPageGroup}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          <Route
            path="roles/groups"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <RolesGroups
                    categories={categories}
                    extraInfo={extraInfoRoles}
                    selectedRole={selectedRole}
                    setSelectedSubcategory={setSelectedSubcategory}
                    setHandleUpperCategoryIdParam={
                      setHandleUpperCategoryIdParam
                    }
                    setIsPageGroup={setIsPageGroup}
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                  />
                </div>
              </>
            }
          />
          <Route
            path="featured/articles"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <FeaturedContentItem
                    dataFeaturedContent={dataFeaturedContent}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          <Route
            path="folder"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <MyFolderPage userInfoSSFF={userInfoSSFF} />
                  <Footer />
                </div>
              </>
            }
          />
          <Route
            path="/folder/:section"
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp
                  isInactive={isInactive}
                  setIsInactive={setIsInactive}
                  handleTokenRefresh={handleTokenRefresh}
                />
                <div className="container">
                  <MyFolderSection
                    // selectedSection={selected}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
