import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { getUserInfoSSFF } from '../../services/apiSSFF';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  sessionUserStartInbenta,
  USER_TYPE_TO_INBENTA_ID,
} from '../../services/api';
import { TeamsFxContext } from '../../Context';

const ProtectedRoutes = ({
  setUserInfoSSFF,
  setLoggedIn,
  loggedIn,
  setLoggedInDisplayName,
}) => {
  const isProduction = true;
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const isAuthenticatedMSAL = useIsAuthenticated();
  const [idToken, setIdToken] = useState(localStorage.getItem('idToken'));
  // const idToken = localStorage.getItem('idToken');

  const setUser = useCallback(
    (user) => {
      setLoggedInDisplayName(user.displayName);
    },
    [setLoggedInDisplayName]
  );

  // Load username either from TeamsFx or MSAL
  useEffect(() => {
    // Get username either with Teams auth or MSAL auth
    if (teamsUserCredential) {
      teamsUserCredential
        .getUserInfo()
        .then((userInfo) => {
          setUser({
            displayName: userInfo.displayName,
          });
        })
        .catch((error) => {
          console.error(
            'ProtectedRoutes: Error getting Teams user info: ',
            error
          );
        });

        teamsUserCredential
        .getToken([])
        .then((ssoToken) => {
          if (ssoToken) {
            localStorage.setItem('idToken', ssoToken.token);
            setIdToken(ssoToken.token);
          }
        })
        .catch((error) => {
          console.error('Error getting SSO token:', error);
        });

    } else {
      if (isAuthenticatedMSAL) {
        const account = instance.getAccountByUsername(accounts[0].username);
        const idToken = account?.idToken;

        if (idToken) {
          localStorage.setItem('idToken', idToken);
          setIdToken(idToken);
        }
      } else {
        console.debug(
          'ProtectedRoutes: No TeamsFx context and no user logged into MSAL yet...'
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    teamsUserCredential,
    isAuthenticatedMSAL,
    accounts,
    instance,
    setUser,
  ]);

  // Load SSFF
  useEffect(() => {
    if (isProduction && localStorage.getItem('idToken')) {
      getUserInfoSSFF()
        .then((userInfo) => {
          //const { userInformation, token } = userInfo;
          const { userInformation } = userInfo;
          if (
            userInformation.d.results.length !== 0 &&
            USER_TYPE_TO_INBENTA_ID[userInformation.d.results[0].custom03] !==
              undefined
          ) {
            setUserInfoSSFF(userInformation.d.results[0]);
            //localStorage.setItem('jwtToken', token)

            sessionUserStartInbenta(
              userInformation.d.results[0].custom03,
              userInformation.d.results[0].custom15,
              userInformation.d.results[0].username
            )
              .then((response) => setLoggedIn(true))
              .catch((err) => {
                console.error(
                  'ProtectedRoutes: Error calling sessionUserStartInbenta'
                );
                console.error(err);
              });
          } else {
            console.warn('ProtectedRoutes: Usuario no tiene aceso...');
            navigate('/');
          }
        })
        .catch((err) => {
          console.error('ProtectedRoutes: Error calling SSFF');
          console.error(err);
        });
    } else {
      console.log(
        'ProtectedRoutes: No TeamsFx context and no user logged into MSAL yet.'
      );
    }
  }, [
    accounts,
    setUserInfoSSFF,
    loggedIn,
    setLoggedIn,
    navigate,
    isProduction,
    idToken,
  ]);

  if (!teamsUserCredential && !isAuthenticatedMSAL && !loggedIn) {
    console.debug(
      'REDIRECT: ProtectedRoutes: Not in Teams, authenticated with MSAL or logged in. Returning to root.'
    );
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
