/*
AUTHENTICATION API DOC: 
a. Requesting Authentication
All requests must be authenticated to access the Inbenta APIs. To do this, send the API key in a x-inbenta-key header.
b. Requesting Authorization
Requests must be authorized. To do this, you exchange either your Secret or your Domain Key for an access token.
Use the /auth endpoint to obtain the access token. The token authorizes all requests to other API endpoints, with the format Bearer <your_access_token>.
*/

const BASE_URL = process.env.REACT_APP_BASE_URL_ENV;
const API_KEY = process.env.REACT_APP_API_KEY;
const TICKETING_API_KEY = process.env.REACT_APP_TICKETING_API_KEY;
const TICKETING_DOMAIN_KEY = process.env.REACT_APP_TICKETING_DOMAIN_KEY;
const DOMAIN_KEY = process.env.REACT_APP_DOMAIN_KEY;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const TICKETING_SECRET_KEY = process.env.REACT_APP_TICKETING_SECRET_KEY;

// Se declaran variables globales para almacenar el token de autenticación, el tiempo de expiración del token y la API de conocimiento.
let authToken = null; // Almacena el token de autenticación globalmente
let tokenExpirationTime = 0; // Almacena el tiempo de expiración del token
let knowledgeApi = null; // Almacena la api para la base de conocimiento

let authTokenTicketing = null; // Almacena el token de autenticación globalmente
let tokenExpirationTimeTicketing = 0; // Almacena el tiempo de expiración del token
let ticketingApi = null; // Almacena la api para la base de conocimiento

let sessionToken = null;
//Función que gestiona un delay entre peticiones.
const throttle = async (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const isProduction = true; // En ejeccución DEV es false

////FETCH AUTHENTICATION: Función de autenticación que obtiene o reutiliza el token de autenticación.
export const authenticate = async () => {
  try {
    //recogemos hora actual
    const currentTime = Date.now();

    const bodyContent = isProduction
      ? { domain_key: DOMAIN_KEY }
      : { secret: SECRET_KEY };

    if (
      !localStorage.getItem('responseAuth') ||
      new Date(
        JSON.parse(localStorage.getItem('responseAuth')).expiration
      ).getTime() <= currentTime
    ) {
      // Si no hay datos en el almacenamiento local o el token ha caducado, se solicita un nuevo token.
      const response = await fetch(`${BASE_URL}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-inbenta-key': API_KEY,
        },
        body: JSON.stringify(bodyContent),
      });

      if (!response.ok) {
        throw new Error('Fallo en la solicitud de autenticación');
      }

      // Se obtiene y almacena el nuevo token y su tiempo de expiración.
      const responseData = await response.json();
      authToken = responseData.accessToken;
      tokenExpirationTime = responseData.expiration; // 20 minutos en milisegundos
      knowledgeApi = responseData.apis.knowledge;
      localStorage.setItem(
        'responseAuth',
        JSON.stringify({
          accessToken: authToken,
          expiration: tokenExpirationTime,
          knowledgeApi: knowledgeApi,
        })
      );
    } else {
      // Si hay datos en el almacenamiento local y el token es válido, se reutiliza.
      const authData = JSON.parse(localStorage.getItem('responseAuth'));
      authToken = authData.accessToken;
      tokenExpirationTime = authData.expiration;
      knowledgeApi = authData.knowledgeApi;
    }

    sessionToken = await sessionStart(authToken);

    return authToken;
  } catch (error) {
    throw new Error('Error en la autenticación');
  }
};

export const authenticateTicketing = async () => {
  try {
    //recogemos hora actual
    const currentTime = Date.now();

    if (
      !localStorage.getItem('responseAuthTicketing') ||
      new Date(
        JSON.parse(localStorage.getItem('responseAuthTicketing')).expiration
      ).getTime() <= currentTime
    ) {
      // Si no hay datos en el almacenamiento local o el token ha caducado, se solicita un nuevo token.
      const response = await fetch(`${BASE_URL}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-inbenta-key': TICKETING_API_KEY,
        },
        body: JSON.stringify({
          domain_key: TICKETING_DOMAIN_KEY,
        }),
      });

      if (!response.ok) {
        throw new Error('Fallo en la solicitud de autenticación ticketing');
      }

      // Se obtiene y almacena el nuevo token y su tiempo de expiración.
      const responseData = await response.json();
      authTokenTicketing = responseData.accessToken;
      tokenExpirationTimeTicketing = responseData.expiration; // 20 minutos en milisegundos
      ticketingApi = responseData.apis.ticketing;
      localStorage.setItem(
        'responseAuthTicketing',
        JSON.stringify({
          accessToken: authTokenTicketing,
          expiration: tokenExpirationTimeTicketing,
          ticketingApi: ticketingApi,
        })
      );
    } else {
      // Si hay datos en el almacenamiento local y el token es válido, se reutiliza.
      const authData = JSON.parse(
        localStorage.getItem('responseAuthTicketing')
      );
      authTokenTicketing = authData.accessToken;
      tokenExpirationTimeTicketing = authData.expiration;
      ticketingApi = authData.ticketingApi;
    }
    return authTokenTicketing;
  } catch (error) {
    throw new Error('Error en la autenticación ticketing');
  }
};

export const createTicket = async (
  title,
  message,
  juridicalSSFF,
  queue,
  attachments,
  idUserInbenta
) => {
  try {
    const token = await authenticateTicketing(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${ticketingApi}/v1/tickets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': TICKETING_API_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        title: title,
        creator: idUserInbenta,
        message: message,
        attachments: attachments,
        source: USER_TYPE_TO_INBENTA_ID[juridicalSSFF].MailSource,
        language: 2,

        queue: queue,
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al crear ticket ');
    }
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error('Fallo al crear ticket  ' + error);
  }
};

export const isUserExist = async (email) => {
  try {
    const token = await authenticateTicketing(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${ticketingApi}/v1/users?address=${email}`, {
      headers: {
        'x-inbenta-key': TICKETING_API_KEY,
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('fallo al recuperar usuario de inbenta');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error('fallo al recuperar usuario de inbenta ' + error);
  }
};

export const createTicketAttach = async (
  title,
  message,
  juridicalSSFF,
  queue,
  attachments
) => {
  try {
    const token = await authenticateTicketing(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${ticketingApi}/v1/tickets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': TICKETING_API_KEY,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        title: title,
        creator: getUserTypeInbenta(juridicalSSFF),
        message: message,
        attachments: attachments,

        source: USER_TYPE_TO_INBENTA_ID[juridicalSSFF].MailSource,
        language: 2,

        queue: queue,
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al crear ticket ');
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error('Fallo al crear ticket  ' + error);
  }
};

export const attachMedia = async (fileName, fileContent) => {
  try {
    const token = await authenticateTicketing(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${ticketingApi}/v1/media`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': TICKETING_API_KEY,
        Authorization: `Bearer ${token}`,
      },
      // body:postFields
      body: JSON.stringify({
        name: fileName,
        content: fileContent,
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al al subir archivo ');
    }
    const responseData = await response.json();

    return responseData.uuid;
  } catch (error) {
    throw new Error('Fallo al al subir archivo  ' + error);
  }
};

////FETCH REFRESH TOKEN: Función para renovar el token de autenticación.
//API DOC: Use this POST method to refresh the accessToken and receive a new access token and expiration period.
export const handleTokenRefresh = async () => {
  try {
    //const currentTime = Date.now();
    const response = await fetch(`${BASE_URL}/refreshToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Fallo en la solicitud de refresco');
    }

    // Se obtiene y almacena el nuevo token y su tiempo de expiración.
    const responseData = await response.json();
    authToken = responseData.accessToken;
    tokenExpirationTime = responseData.expiration;
    localStorage.setItem(
      'responseAuth',
      JSON.stringify({
        accessToken: authToken,
        expiration: tokenExpirationTime,
        knowledgeApi: knowledgeApi,
      })
    );
  } catch (error) {
    console.log('error', error.message);
  }
};

// Se establece un intervalo para renovar automáticamente el token cada 15 minutos.
const tokenRefreshInterval = setInterval(handleTokenRefresh, 15 * 60 * 1000);
// Código para detener el intervalo de refresco de token cuando ya no sea necesario
// clearInterval(tokenRefreshInterval);

////FETCH CATEGORIES: Función para obtener las categorías de la API.
//API DOC: Returns all child categories, from the root category downwards, until maxDepth is reached.
export const fetchCategories = async () => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(`${knowledgeApi}/v1/categories?maxDepth=200`, {
      headers: {
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Fallo al obtener categorías');
    }

    const responseData = await response.json();
    return responseData.results.map((category) => ({
      id: category.id,
      name: category.name,
      parent: category.parent,
      depth: category.depth,
    }));
  } catch (error) {
    throw new Error('Fallo al obtener categorías');
  }
};

////FETCH CATEGORIES DESCRIPCION (EXTRA-INFO): Función para obtener la descripción de los contenidos de cada categoría mediante el campo 'extra-info'.
export const fetchCategoriesDescription = async () => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    // await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/app/data/DescripcionCategorias?name=Categorias_NuevoPeoplEX`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener categorías');
    }

    const responseData = await response.json();
    return responseData.results[0].value.Elemento_Categoria;
  } catch (error) {
    throw new Error('Fallo al obtener extra info de las categorías');
  }
};

////FETCH SUBCATEGORIES: Función para obtener las subcategorías de una categoría de la API.
//API DOC: Returns child categories, from a category identified with a categoryId parameter downwards, until maxDepth is reached.
export const fetchSubcategories = async (categoryId) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    //await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/categories/${categoryId}`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener subcategorías');
    }

    const responseData = await response.json();
    return responseData.results.map((e) => ({ id: e.id, name: e.name }));
  } catch (error) {
    throw new Error('Fallo al obtener subcategorías');
  }
};

////FETCH ARTICLES: Función para obtener los artículos de una subcategoría de la API.
//API DOC: Returns the contents of the specified categories ordered like in the Knowledge module in Knowledge > categories.
export const fetchSubcategoriesArticles = async (
  subcategoryId,
  juridicalSSFF
) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    //await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/categories/${subcategoryId}/contents?length=100`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
          'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener subcategorías');
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error) {
    throw new Error('Fallo al obtener subcategorías');
  }
};

////FETCH SEARCH: Función para buscar artículos en la API.
//API DOC: Returns content that matches the query, using semantic search filtered by parameters conditions.
export const fetchSearchArticles = async (
  queryParam,
  upperCategoryIdParam,
  juridicalSSFF
) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${knowledgeApi}/v1/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': API_KEY,
        'x-inbenta-env': 'preproduction',
        //////----------CAMBIAR X-INBENTA-ENV PARA DESPLIEGUE----------//////
        //production (default): This environment logs the data for the reports displayed in the Inbenta dashboards and retrieves the contents/categories published in live.
        //preproduction: This environment does not log any data and retrieves the contents/categories published in live.
        //development: This environment does not log any data and retrieves the contents/categories published in edit.
        Authorization: `Bearer ${token}`,
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
      },
      body: JSON.stringify({
        query: queryParam, // placeholder del campo de busqueda
        ////Por añadir
        categoryId: upperCategoryIdParam, // Id de la categoría madre (BusinessEX-40/ PeoplEX-133/ Contenidos Destacados-134/ Roles-138)
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo en la busqueda de articulos');
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error) {
    throw new Error('Fallo en la busqueda de articulos' + error);
  }
};

////FETCH FEATURED CONTENT (CONTENIDO DESTACADO): Función para obtener los artículos marcados como 'destacados' en la API.
//API DOC: Returns the content marked as push and the ad-hoc push contents.
export const fetchFeaturedContent = async (juridicalSSFF) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(`${knowledgeApi}/v1/contents/push`, {
      headers: {
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
      },
    });

    if (!response.ok) {
      throw new Error('Fallo al obtener Contenido Destacado');
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error) {
    throw new Error('Fallo al obtener Contenido Destacado');
  }
};

////FETCH FREQUENT QUESTIONS (PREGUNTAS FRECUENTES): Función para obtener los artículos más visitados por los usuarios.
//API DOC: Returns most clicked content items.
export const fetchfrequentQuestions = async (juridicalSSFF) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(`${knowledgeApi}/v1/contents/popular`, {
      headers: {
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
      },
    });

    if (!response.ok) {
      throw new Error('Fallo al obtener preguntas frequentes');
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error) {
    throw new Error('Fallo al obtener preguntas frequentes');
  }
};

////FETCH RELATED CONTENT (CONTENIDO RELACIONADO): Función para obtener los artículos marcados como 'relacionados' en la API.
// API DOC: Returns related content for content identified with a contentId parameter.
export const fetchRelatedContent = async (articleId) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/contents/${articleId}/related`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener el contenido relacionado');
    }
    const responseData = await response.json();
    return responseData.results;
  } catch (error) {
    throw new Error('Fallo al obtener el contenido relacionado');
  }
};

////FETCH AUTOCOMPLETER: Función para obtener los artículos de la API mediante autocompletado en el searcher
// API DOC: This endpoint provides all the data needed to implement an autocompleter. For a quick response, and to help the end user while typing, Inbenta recommends that you handle this functionality on the front end, and retrieve this data when initialising the page.
// The basic steps to create an autocompleter using the API is to:
// Retrieve the data that you want to autocomplete with this endpoint
// Store it on the front end (e.g. the navigator local storage)
// Listen to the input of the end user that you want to autocomplete and compare each string with the stored values.1
// 1 Inbenta strongly recommends that you add methods to avoid applying the string comparison on each key typed by the user. (e.g. debouncing delays the comparison function until the user stops typing for s specified preiod, such as 200ms.)

export const fetchAutocompleter = async (/*juridicalSSFF*/) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(`${knowledgeApi}/v1/autocompleter/data`, {
      headers: {
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        //"x-inbenta-user-type": getUserTypeInbenta(juridicalSSFF)
      },
    });

    if (!response.ok) {
      throw new Error('Fallo al obtener los articulos del autocompleter');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error('Fallo al obtener articulos del autocompleter');
  }
};

//extra info Roles
export const fetchExtraInfoRoles = async () => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/app/data/DescripcionCategorias?name=Categorias`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener categorías');
    }

    const responseData = await response.json();
    return responseData.results[0].value.Categorias;
  } catch (error) {
    throw new Error('Fallo al obtener extra info de las categorías');
  }
};

//extra info Contenido Destacado
export const fetchExtraInfoFeatContent = async (juridicalSSFF) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    await throttle(1000); // Introduce un retraso de 1 segundo entre las solicitudes

    const response = await fetch(
      `${knowledgeApi}/v1/app/data/PushContents?name=PushContents_General`,
      {
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${token}`,
          'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
        },
      }
    );

    if (!response.ok) {
      throw new Error('Fallo al obtener');
    }

    const responseData = await response.json();
    return responseData.results[0].value.Descripciones;
  } catch (error) {
    throw new Error('Fallo al obtener extra info del Contenido Destacado');
  }
};

export const trackingCaptureEvent = async (event, clickCode, juridicalSSFF) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    const response = await fetch(`${knowledgeApi}/v1/inbtrck/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        'x-inbenta-session': sessionToken,
        'x-inbenta-env': 'preproduction',
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
        'x-inbenta-source': 'peoplex',
      },
      body: JSON.stringify({
        type: event,
        data: {
          code: clickCode,
        },
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al capturar el evento ');
    }
    // const responseData = await response.json();

    // return responseData;
  } catch (error) {
    throw new Error('Fallo al capturar el evento ' + error);
  }
};

export const trackingCaptureRateEvent = async (
  event,
  rateCode,
  comment,
  value,
  juridicalSSFF
) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación

    const response = await fetch(`${knowledgeApi}/v1/inbtrck/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        'x-inbenta-session': sessionToken,
        'x-inbenta-env': 'preproduction',
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
        'x-inbenta-source': 'peoplex',
      },
      body: JSON.stringify({
        type: event,
        data: {
          code: rateCode,
          value: value,
          comment: comment,
        },
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al capturar el evento rate');
    }
    // const responseData = await response.json();

    // return responseData;
  } catch (error) {
    throw new Error('Fallo al capturar el evento rate ' + error);
  }
};

export const sessionUserStartInbenta = async (
  juridicalSSFF,
  isManager,
  email
) => {
  try {
    const token = await authenticate(); // Obtiene o reutiliza el token de autenticación
    const response = await fetch(`${knowledgeApi}/v1/inbtrck/session/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-inbenta-key': API_KEY,
        Authorization: `Bearer ${token}`,
        'x-inbenta-session': sessionToken,
        'x-inbenta-env': 'preproduction',
        'x-inbenta-user-type': getUserTypeInbenta(juridicalSSFF),
        'x-inbenta-source': 'peoplex',
      },
      body: JSON.stringify({
        data: {
          name: email,
          inbenta_extra_field1: isManager === 'Y' ? 'manager' : isManager,
        },
      }),
    });

    if (!response.ok) {
      throw new Error('Fallo al inicar la sesion del usuario con inbenta');
    }
  } catch (error) {
    throw new Error(
      'Fallo al inicar la sesion del usuario con inbenta ' + error
    );
  }
};

//sessionstart
const sessionStart = async (authenticationToken) => {
  let sessionTokenStart;
  if (!sessionStorage.getItem('sessionInbenta')) {
    try {
      const response = await fetch(`${knowledgeApi}/v1/inbtrck/session`, {
        method: 'POST',
        headers: {
          'x-inbenta-key': API_KEY,
          Authorization: `Bearer ${authenticationToken}`,
          'x-inbenta-env': 'preproduction',
          'x-inbenta-source': 'peoplex',
        },
      });

      if (!response.ok) {
        throw new Error('Fallo al obtener la sesión de inbenta');
      }

      const responseSession = await response.json();

      sessionTokenStart = responseSession.sessionToken;

      sessionStorage.setItem('sessionInbenta', sessionTokenStart);
    } catch (error) {
      throw new Error('Fallo al obtener la sesión de inbenta');
    }
  } else {
    sessionTokenStart = sessionStorage.getItem('sessionInbenta');
  }
  return sessionTokenStart;
};

const getUserTypeInbenta = (juridicalSSFF) => {
  const USER_TYPE_TO_INBENTA_ID = {
    9999: {
      // TEST
      kmIdUserType: 16,
      queueId: 7,
      hcRoomId: 7,
      hcSource: 3,
      hcCompanyName: 'TEST',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: 'ea74368c - [queue:5_29_3]',
      ChatActive: 0,
      MailSource: 18,
      chatBotId: 'T_Digital',
    },
    '0250_ESP': {
      // TSA - Telefonica SA
      kmIdUserType: 5,
      queueId: 7,
      hcRoomId: 7,
      hcSource: 3,
      hcCompanyName: 'SA - Telefonica SA',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 1,
      chatBotId: 'TSA',
    },
    '250_ESP': {
      // TSA - Telefonica SA
      kmIdUserType: 5,
      queueId: 7,
      hcRoomId: 7,
      hcSource: 3,
      hcCompanyName: 'TSA - Telefonica SA',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 1,
      chatBotId: 'TSA',
    },
    '0250_BEL': {
      // TSA - Telefonica SA
      kmIdUserType: 5,
      queueId: 7,
      hcRoomId: 7,
      hcSource: 3,
      hcCompanyName: 'TSA - Telefonica SA',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 1,
      chatBotId: 'TSA',
    },
    '250_BEL': {
      // TSA - Telefonica SA
      kmIdUserType: 5,
      queueId: 7,
      hcRoomId: 7,
      hcSource: 3,
      hcCompanyName: 'TSA - Telefonica SA',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 1,
      chatBotId: 'TSA',
    },
    '0024': {
      kmIdUserType: 10,
      queueId: 13,
      hcRoomId: 13,
      hcSource: 10,
      hcCompanyName: 'Telefónica I+D España',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '1F5569DB - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 24,
      chatBotId: 'TID',
    },
    1412: {
      kmIdUserType: 9,
      queueId: 14,
      hcRoomId: 14,
      hcSource: 11,
      hcCompanyName: 'Telefonica Global Solutions (TGS)',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: 'D0C8EE0B - [Escribe aquí el asunto del correo]',
      ChatActive: 1,
      MailSource: 25,
      chatBotId: 'TGS_España',
    },
    1893: {
      // TELXIUS CABLE ESPAÑA
      kmIdUserType: 13,
      queueId: 35,
      hcRoomId: 35,
      hcSource: 36,
      hcCompanyName: 'TELXIUS CABLE ESPAÑA',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '5107b55b - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 31,
      chatBotId: 'T_Cable_España',
    },
    1702: {
      // TELXIUS TELECOM
      kmIdUserType: 12,
      queueId: 37,
      hcRoomId: 37,
      hcSource: 37,
      hcCompanyName: 'TELXIUS TELECOM',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: 'ff54a089c - [Escribe aquí el asunto del correo]',
      ChatActive: 1,
      MailSource: 32,
      chatBotId: 'T_Telecom',
    },
    1343: {
      // TCE
      kmIdUserType: 14,
      queueId: 38,
      hcRoomId: 38,
      hcSource: 39,
      hcCompanyName: 'TCE',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: 'a931089 - [Escribe aquí el asunto del correo]',
      ChatActive: 1,
      MailSource: 41,
      chatBotId: 'TCE',
    },
    1017: {
      // T. HISPAN
      kmIdUserType: 16,
      queueId: 46,
      hcRoomId: 46,
      hcSource: 49,
      hcCompanyName: 'T. HISPAN',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: 'VC4gSEI - [Escribe aquí el asunto del correo]',
      ChatActive: 1,
      MailSource: 47,
      chatBotId: 'T_Hispam',
    },
    1583: {
      kmIdUserType: 17,
      queueId: 50,
      hcRoomId: 13,
      hcSource: 10,
      hcCompanyName: 'Telefonica Open Innovation',
      linkLegal: 'https://www.telefonica.com/es/aviso-legal',
      emailTicketing: 'preguntanos@personas.telefonica.com',
      subjectTicketing: '1F5569DB - [Escribe aquí el asunto del correo]',
      ChatActive: 0,
      MailSource: 24,
      chatBotId: 'TID',
    },
  };
  return USER_TYPE_TO_INBENTA_ID[juridicalSSFF].kmIdUserType;
};
export const USER_TYPE_TO_INBENTA_ID = {
  9999: {
    // TEST
    kmIdUserType: 16,
    queueId: 7,
    hcRoomId: 7,
    hcSource: 3,
    hcCompanyName: 'TEST',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: 'ea74368c - [queue:5_29_3]',
    ChatActive: 0,
    MailSource: 18,
    chatBotId: 'T_Digital',
  },
  '0250_ESP': {
    // TSA - Telefonica SA
    kmIdUserType: 5,
    queueId: 18,
    hcRoomId: 7,
    hcSource: 3,
    hcCompanyName: 'SA - Telefonica SA',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 1,
    chatBotId: 'TSA',
  },
  '250_ESP': {
    // TSA - Telefonica SA
    kmIdUserType: 5,
    queueId: 18,
    hcRoomId: 7,
    hcSource: 3,
    hcCompanyName: 'TSA - Telefonica SA',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 1,
    chatBotId: 'TSA',
  },
  '0250_BEL': {
    // TSA - Telefonica SA
    kmIdUserType: 5,
    queueId: 18,
    hcRoomId: 7,
    hcSource: 3,
    hcCompanyName: 'TSA - Telefonica SA',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 1,
    chatBotId: 'TSA',
  },
  '250_BEL': {
    // TSA - Telefonica SA
    kmIdUserType: 5,
    queueId: 18,
    hcRoomId: 7,
    hcSource: 3,
    hcCompanyName: 'TSA - Telefonica SA',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '440A37E3 - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 1,
    chatBotId: 'TSA',
  },
  '0024': {
    kmIdUserType: 10,
    queueId: 26,
    hcRoomId: 13,
    hcSource: 10,
    hcCompanyName: 'Telefónica Innovacion Digital',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '1F5569DB - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 24,
    chatBotId: 'TID',
  },
  1583: {
    kmIdUserType: 17,
    queueId: 50,
    hcRoomId: 13,
    hcSource: 10,
    hcCompanyName: 'Telefonica Open Innovation',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '1F5569DB - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 24,
    chatBotId: 'TID',
  },
  1412: {
    kmIdUserType: 9,
    queueId: 25,
    hcRoomId: 14,
    hcSource: 11,
    hcCompanyName: 'Telefonica Global Solutions (TGS)',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: 'D0C8EE0B - [Escribe aquí el asunto del correo]',
    ChatActive: 1,
    MailSource: 25,
    chatBotId: 'TGS_España',
  },
  1893: {
    // TELXIUS CABLE ESPAÑA
    kmIdUserType: 13,
    queueId: 31,
    hcRoomId: 35,
    hcSource: 36,
    hcCompanyName: 'TELXIUS CABLE ESPAÑA',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: '5107b55b - [Escribe aquí el asunto del correo]',
    ChatActive: 0,
    MailSource: 31,
    chatBotId: 'T_Cable_España',
  },
  1702: {
    // TELXIUS TELECOM
    kmIdUserType: 12,
    queueId: 32,
    hcRoomId: 37,
    hcSource: 37,
    hcCompanyName: 'TELXIUS TELECOM ',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: 'ff54a089c - [Escribe aquí el asunto del correo]',
    ChatActive: 1,
    MailSource: 32,
    chatBotId: 'T_Telecom',
  },
  1343: {
    // TCE
    kmIdUserType: 14,
    queueId: 41,
    hcRoomId: 38,
    hcSource: 39,
    hcCompanyName: 'TCE',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: 'a931089 - [Escribe aquí el asunto del correo]',
    ChatActive: 1,
    MailSource: 41,
    chatBotId: 'TCE',
  },
  1017: {
    // T. HISPAN
    kmIdUserType: 16,
    queueId: 47,
    hcRoomId: 46,
    hcSource: 49,
    hcCompanyName: 'T. HISPAN',
    linkLegal: 'https://www.telefonica.com/es/aviso-legal',
    emailTicketing: 'preguntanos@personas.telefonica.com',
    subjectTicketing: 'VC4gSEI - [Escribe aquí el asunto del correo]',
    ChatActive: 1,
    MailSource: 47,
    chatBotId: 'T_Hispam',
  },
};
