import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
//styles
import '../styles/searcher.css';

// images
import iconSearch from '../../images/search.svg';

const Searcher = (props, { loggedInDisplayName }) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const isProduction = true; // En ejecución DEV es false

  // Inicializa la variable "navigate" para cambiar de ruta en la navegación
  const navigate = useNavigate();

  // Ref para el elemento de autocompletado
  const autocompleteRef = useRef(null);

  // Controlador de evento para cerrar el autocompletado cuando se hace clic en cualquier lugar fuera de él
  const handleClickOutside = (event) => {
    if (
      autocompleteRef.current &&
      !autocompleteRef.current.contains(event.target)
    ) {
      // El clic ocurrió fuera del elemento, ocultar el autocompletado
      setFilteredAutocompletedArticles([]);
    }
  };

  // Agregar un controlador de eventos al documento principal cuando se monta el componente
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    // Limpiar el controlador de eventos cuando se desmonte el componente
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Función que maneja el cambio en el campo de búsqueda
  const handleChange = (ev) => {
    ev.preventDefault();
    props.handleFilterSearcher(ev.target.value);
    //debounce
    const { value } = ev.target;
    setSearchTerm(value);
    debouncedAutocomplete(value);
  };

  // Función que maneja la tecla Enter para iniciar la búsqueda
  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        // Mover hacia arriba en la lista de autocompletado
        setSelectedItemIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case 'ArrowDown':
        // Mover hacia abajo en la lista de autocompletado
        setSelectedItemIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredAutocompletedArticles.length - 1)
        );
        break;
      case 'Enter':
        // Realizar la acción deseada al presionar Enter
        if (selectedItemIndex !== -1) {
          // Si hay un elemento seleccionado, realiza la acción correspondiente
          const selectedArticle =
            filteredAutocompletedArticles[selectedItemIndex];
          props.handleFilterSearcher(selectedArticle.repr);
          props.setHandleUpperCategoryIdParam(
            '133' +
              (props.userInfoSSFF.custom15 === 'Y' ||
              props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)'
                ? ',138'
                : '') +
              (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
          );
          navigate('/search/articles');
        } else {
          // Si no hay un elemento seleccionado, realiza la búsqueda directa
          props.handleFilterSearcher(searchTerm);
          props.setHandleUpperCategoryIdParam(
            '133' +
              (props.userInfoSSFF.custom15 === 'Y' ||
              props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)'
                ? ',138'
                : '') +
              (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
          );
          navigate('/search/articles');
        }
        break;
      default:
        break;
    }

    // Asegúrate de que el elemento seleccionado sea visible al desplazarse
    if (autocompleteRef.current) {
      const items = autocompleteRef.current.childNodes;

      if (items.length > 0) {
        let newIndex = selectedItemIndex;

        switch (event.key) {
          case 'ArrowUp':
            newIndex = Math.max(selectedItemIndex - 1, -1);
            break;
          case 'ArrowDown':
            newIndex = Math.min(selectedItemIndex + 1, items.length - 1);
            break;
          default:
            break;
        }

        if (newIndex !== selectedItemIndex) {
          // Actualiza el estado solo si el índice cambia
          setSelectedItemIndex(newIndex);

          const selectedElement = items[newIndex];

          // Comprueba si el elemento seleccionado es válido antes de realizar el scroll
          if (selectedElement) {
            selectedElement.scrollIntoView({
              behavior: 'auto', // Usa 'auto' en lugar de 'smooth'
              block: 'nearest',
            });
          }
        }
      }
    }
  };

  // Función para normalizar texto del campo de búsqueda eliminando caracteres especiales y tildes
  const normalizeText = (text) => {
    return text
      .normalize('NFD') // Normaliza tildes
      .replace(/[\u0300-\u036f]/g, '') // Elimina tildes
      .replace(/[^a-zA-Z0-9 ]/g, '') // Elimina caracteres especiales excepto espacios
      .toLowerCase(); // Convierte a minúsculas
  };

  //Función para normalizar el texto del displayname del usuario.
  const normalizeDisplayName = (text) => {
    return text
      .split(' ') // Dividimos el texto en palabras
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Convierte a mayus el primer carácter de cada palabra y el resto en minus.
      .join(' '); // Unimos las palabras en un único string
  };

  const displayName = props.isInTeams
    ? normalizeDisplayName(props.loggedInDisplayName)
    : isProduction && isAuthenticated
    ? normalizeDisplayName(accounts[0].name)
    : props.userInfoSSFF.userId;

  // Función debounce del autocomplete
  /* El método "debouncing" en JavaScript es una técnica que se utiliza para controlar y gestionar el retraso o "delay" en la ejecución de una función o acción, especialmente en respuesta a eventos. El objetivo principal del debouncing es evitar que una función se ejecute repetidamente en rápida sucesión. El proceso de debouncing implica el uso de un temporizador para retrasar la ejecución de la función. Cuando ocurre el evento, se establece un temporizador que retrasa la ejecución de la función. Si el evento se dispara nuevamente antes de que el temporizador se complete, se reinicia el temporizador.*/
  const [searchTerm, setSearchTerm] = useState(''); // 'searchTerm' es una variable que almacena el término de búsqueda y 'setSearchTerm' es la función para actualizar ese estado.
  const [filteredAutocompletedArticles, setFilteredAutocompletedArticles] =
    useState([]); //'filteredAutocompletedArticles' es una variable que almacena los articulos filtrados del autocomplete

  const debouncedAutocomplete = useCallback(
    // Creamos una función debounced llamada 'debouncedAutocomplete' utilizando el hook 'useCallback'.
    debounce((value) => {
      // Esta función se encargará de realizar la lógica de autocompletar con el término de búsqueda.
      // Normaliza el valor de búsqueda
      const normalizedValue = normalizeText(value);

      //##TEMPORAL
      const businessExIds = [
        80,
        257,
        359,
        ...Array.from({ length: 3 }, (_, i) => 370 + i),
        ...Array.from({ length: 26 }, (_, i) => 402 + i),
        ...Array.from({ length: 3 }, (_, i) => 431 + i),
        ...Array.from({ length: 13 }, (_, i) => 436 + i),
        ...Array.from({ length: 19 }, (_, i) => 451 + i),
        ...Array.from({ length: 17 }, (_, i) => 471 + i),
        ...Array.from({ length: 6 }, (_, i) => 897 + i),
        491,
      ];
      const rolesIds = [
        ...Array.from({ length: 310 }, (_, i) => 499 + i), //Ids: 499 al 704
      ];

      let copyAutocompleter = [...props.autocompleteArticles];

      if (
        props.userInfoSSFF.custom15 !== 'Y' ||
        props.userInfoSSFF.custom03 === '1893' ||
        props.userInfoSSFF.custom03 === '1702' ||
        props.userInfoSSFF.custom03 === '1017'
      ) {
        copyAutocompleter = copyAutocompleter.filter(
          (article) =>
            !businessExIds.includes(article.id) &&
            !rolesIds.includes(article.id)
        );

        if (props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)') {
          copyAutocompleter = copyAutocompleter.concat(
            props.autocompleteArticles.filter(
              (article) => article.id >= 658 && article.id <= 809
            )
          );
        }
      } else {
        copyAutocompleter = copyAutocompleter.filter(
          (article) => !rolesIds.includes(article.id)
        );
        if (props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)') {
          copyAutocompleter = copyAutocompleter.concat(
            props.autocompleteArticles.filter(
              (article) => article.id >= 658 && article.id <= 809
            )
          );
        } else {
          copyAutocompleter = copyAutocompleter.concat(
            props.autocompleteArticles.filter(
              (article) => article.id >= 499 && article.id <= 657
            )
          );
        }
      }
      //##TEMPORAL

      // Realiza la comparación y filtra los artículos coincidentes
      const filteredResults = copyAutocompleter.filter((article) => {
        // Normaliza el texto del artículo y luego compara
        const normalizedArticle = normalizeText(article.repr);

        return normalizedArticle.includes(normalizedValue);
      });

      // Actualiza el estado con los resultados coincidentes
      setFilteredAutocompletedArticles(filteredResults);
    }, 300), // 300 milisegundos de retraso
    [props.autocompleteArticles] // El segundo argumento es un arreglo de dependencias vacío, lo que significa que esta función no depende de ningún valor externo.
  );

  /* Explicación
  1.- Se utiliza el hook useState para crear una variable de estado llamada searchTerm y su función correspondiente setSearchTerm. Esta variable de estado se utilizará para almacenar el término de búsqueda que el usuario ingresa en un campo de entrada.
  2.- Luego, se utiliza el hook useCallback para crear una función debounced llamada debouncedAutocomplete. Esta función se encargará de realizar la lógica de autocompletar con el término de búsqueda.
  3.- Dentro de la función debouncedAutocomplete, se utiliza la función debounce (que asumimos que está definida en otro lugar) para aplicar un retraso de 300 milisegundos antes de ejecutar la lógica de autocompletar. Esto asegura que la función de autocompletar se ejecute solo después de que el usuario haya dejado de escribir durante 300 milisegundos.
  4.- Dentro de la función debounced, se muestra una alerta con el mensaje "AUTOCOMPLETE" cuando se activa el autocompletado. Esto puede servir como una notificación visual para el usuario.
  HOOK USECALLBACK
  El hook useCallback en React se utiliza para memoizar funciones, lo que significa que ayuda a evitar que una función se cree de nuevo en cada renderizado del componente, a menos que sus dependencias cambien. Esto puede ser útil en situaciones en las que pasas funciones como propiedades a componentes hijos y deseas evitar que los componentes hijos se vuelvan a renderizar innecesariamente.
  */

  return (
    <>
      <section className="searcher__section">
        <h2 className="searcher__text">¿Qué necesitas, {displayName}?</h2>
        <div className="searcher__container">
          <img src={iconSearch} alt="search_icon" className="icon" />
          {/* <Link
            to="/search/articles"
            onClick={() => props.handleFilterSearcher(props.filterInSearcher)}
          > */}
          {/* </Link> */}
          <p className="¿Qué necesitas?"></p>
          <input
            type="text"
            placeholder="Nóminas, vacaciones..."
            autoComplete="off"
            className="search__input"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={searchTerm}
          />
          {/* Renderiza la lista de resultados solo si hay elementos y searchTerm no está vacío */}
          {searchTerm !== '' && filteredAutocompletedArticles.length > 0 && (
            <ul className="autocomplete__container" ref={autocompleteRef}>
              {filteredAutocompletedArticles.map((article, index) => (
                <Link
                  key={article.id}
                  to="/search/articles"
                  className={`autocomplete__item ${
                    index === selectedItemIndex ? 'selected' : ''
                  }`}
                  onClick={() => {
                    props.handleFilterSearcher(article.repr);
                    props.setHandleUpperCategoryIdParam(
                      '133' +
                        (props.userInfoSSFF.custom15 === 'Y' ||
                        props.userInfoSSFF.division ===
                          'CHIEF PEOPLE OFFICER (DI90009)'
                          ? ',138'
                          : '') +
                        (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
                    );
                  }}
                >
                  <li
                    className={`autocomplete__item ${
                      index === selectedItemIndex ? 'selected' : ''
                    }`}
                  >
                    {article.repr}
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

function debounce(func, delay) {
  let timer; // Variable para almacenar el temporizador de retraso
  return function () {
    // Devolvemos una función que será el "debounced" (con retraso) wrapper para la función original
    clearTimeout(timer); // Limpiamos el temporizador existente (si lo hay) para reiniciar el retraso
    timer = setTimeout(() => {
      // Después de que haya pasado el tiempo de retraso especificado (delay), se ejecutará este bloque de código
      func.apply(this, arguments); // Ejecutamos la función original (func) con los argumentos proporcionados a la función debounced.
      // "this" y "arguments" se refieren a los contextos y argumentos de la función debounced, no de la función original
    }, delay);
  };
}

/* 
Explicación de la función debounce:
1.- debounce es una función que toma dos argumentos: func, que es la función que deseamos ejecutar con retraso, y delay, que es el tiempo en milisegundos que queremos esperar antes de ejecutar la función func.
2.- Dentro de la función debounce, declaramos una variable timer que se utilizará para rastrear el temporizador de retraso.
3.- Devolvemos una función anónima interna (closure) que servirá como el "debounced wrapper" para la función original func. Esta función interna es lo que llamaremos en lugar de func directamente.
4.- Dentro de esta función interna, primero llamamos a clearTimeout(timer) para borrar cualquier temporizador existente que aún esté esperando para ejecutar func. Esto es importante para reiniciar el retraso cada vez que la función interna se llama nuevamente antes de que se cumpla el retraso.
5.- Luego, establecemos un nuevo temporizador utilizando setTimeout. Cuando se cumple el tiempo de delay, se ejecuta el bloque de código en el que llamamos a func.apply(this, arguments). Esto significa que ejecutamos la función func con los mismos argumentos que se pasaron a la función interna debounced.
6.- El uso de apply(this, arguments) permite que la función original func se ejecute con el contexto correcto (this) y los argumentos adecuados. this y arguments se refieren a los valores en el contexto de la función interna debounced, no de la función original.
*/

export default Searcher;
