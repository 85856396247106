import { Link, useNavigate } from 'react-router-dom';
import elipse355 from '../../images/ellipse355.png';
import payrollIcon from '../../images/folder/nominas.svg';
import retentionsIcon from '../../images/folder/retenciones.svg';
import certificateIcon from '../../images/folder/otros_certificados.svg';
import arrowIcon from '../../images/folder/arrow.svg';
import benefitsIcon from '../../images/folder/benefits.svg';
import '../styles/myFolderPage.css';

const MyFolderPage = ({ userInfoSSFF }) => {
  const navigate = useNavigate();

  // Definir las carpetas disponibles
  const folders = [
    {
      id: 'certificates',
      name: 'Certificados',
      icon: certificateIcon,
      description: 'Consulta el resto de certificados',
    },
    {
      id: 'payrolls',
      name: 'Nóminas',
      icon: payrollIcon,
      description: 'Descarga o visualiza tus últimas nóminas y recibos',
    },
    {
      id: 'retentions',
      name: 'Retenciones',
      icon: retentionsIcon,
      description: 'Certificados de retenciones de los últimos años',
    },
    {
      id: 'benefits',
      name: 'Beneficios',
      icon: benefitsIcon,
      description: 'Consulta certificados sobre tus ventajas en Telefónica',
    },
  ];

  const handleNavigation = (folderId) => {
    navigate(`/folder/${folderId}`, { state: { selected: folderId } });
  };

  return (
    <>
      <section className="myFolderPage">
        <div className="myFolderPage__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <Link to="/home" className="myFolderPage__breadcrumb--title">
            INICIO
          </Link>
          <span className="myFolderPage__breadcrumb--text">
            &gt; MI CARPETA
          </span>
        </div>
        <div className="myFolderPage__content">
          <h1 className="myFolderPage__title">Mi carpeta</h1>
          <div className="myFolderPage__options">
            {folders.map((folder) => (
              <div
                key={folder.id}
                className={`myFolderPage__option ${
                  folder.id !== 'certificates'
                    ? 'myFolderPage__buttonDisabled'
                    : ''
                }`}
              >
                {folder.id === 'certificates' ? (
                  <>
                    <div
                      className="myFolderPage__textContent"
                      onClick={() => handleNavigation(folder.id)}
                    >
                      <div className="myFolderPage__imageContent">
                        <img
                          src={folder.icon}
                          alt={folder.name}
                          className="myFolderPage__image"
                        />
                      </div>
                      <h1 className="myFolderPage__groupTitle">
                        {folder.name}
                      </h1>
                      <p className="myFolderPage__description">
                        {folder.description}
                      </p>
                      <div className="myFolderPage__arrowButton">
                        <img
                          src={arrowIcon}
                          alt="arrow"
                          className="myFolderPage__arrow"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="myFolderPage__textContent">
                    <div className="myFolderPage__imageContent">
                      <img
                        src={folder.icon}
                        alt={folder.name}
                        className="myFolderPage__image"
                      />
                    </div>
                    <h1 className="myFolderPage__groupTitle">{folder.name}</h1>
                    <p className="myFolderPage__description">
                      {folder.description}
                    </p>
                    <p className="myFolderPage__soon">Próximamente</p>

                    <div className="myFolderPage__arrowButton">
                      <img
                        src={arrowIcon}
                        alt="arrow"
                        className="myFolderPage__arrow"
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MyFolderPage;
