// Función en el frontend para realizar la solicitud de logout al proxy
/* export const logOut = async (token,msalToken) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
    
            if (token) {
                headers['JWT-Authorization'] = `Bearer ${token}`;
            }
            if (msalToken) {
                headers['x-msal-authorization'] = `Bearer ${msalToken}`;
            }
    
            const response = await fetch('/logOut', {
                method: 'POST',
                headers: headers,
            });
    
            if (response.ok) {
                console.log('Tokens invalidados en el servidor');
                return true;  
            } else {
                console.error('Error al invalidar los tokens en el servidor');
                return false; 
            }
        } catch (error) {
            console.error('Error en la solicitud de logout:', error);
            return false;
        }
}; */


export const logOut = async (msalToken) => {
    try {
        const response = await fetch('/logOut', {
            method: 'POST',
            headers: {
                'x-msal-authorization': `Bearer ${msalToken}`,
                'Content-Type': 'application/json',  
            },
        });

        if (response.ok) {
            console.log('Tokens invalidados en el servidor');
            return true;  
        } else {
            console.error('Error al invalidar los tokens en el servidor');
            return false; 
        }
    } catch (error) {
        console.error('Error en la solicitud de logout:', error);
        return false;
    }
};
