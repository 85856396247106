export const getCertificate = async (userInfo, token) => {
  try {
    const { antiguedad, funcion, salario, jornada } = userInfo;

    const requestBody = {
      antiguedad,
      funcion,
      salario,
      jornada,
    };

    
    const response = await fetch('/getCertificado', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-msal-Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Fallo en la solicitud del certificado');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error('Error al obtener el certificado: ' + error.message);
  }
};
