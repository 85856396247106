import React, { useEffect, useState } from 'react';
import '../styles/article-item.css';
import plus from '../../images/plus.svg';
import minus from '../../images/minus.svg';
import like from '../../images/Like.svg';
import close from '../../images/close.svg';
import RelatedContentItem from './related_content-item';
import { Link } from 'react-router-dom';

import {
  trackingCaptureEvent,
  trackingCaptureRateEvent,
  fetchRelatedContent,
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../services/api';

const articleRoutes = {
  14: '/subcategories/articles/form/fusion',
  20: '/subcategories/articles/form/loan',
  17: '/subcategories/articles/form/healthinsurancebeneficiaries',
  18: '/subcategories/articles/form/advance',
  28: [
    '/subcategories/articles/form/telematic',
    '/subcategories/articles/form/telematicperiods',
  ],
  64: '/subcategories/articles/form/gourmetcard',
  60: '/subcategories/articles/form/lifeinsurancebeneficiaries',
  235: '/subcategories/articles/form/healthinsurance',
  236: 'subcategories/articles/form/lifeinsurance',
  // sharedPayment,
  // incidence,
};

const ArticleItem = ({
  title,
  answer,
  expandedState,
  clickCode,
  rateCode,
  userInfoSSFF,
  articleId,
  contactMe,
}) => {
  // Estado para controlar si el artículo está expandido o no
  const [isExpanded, setIsExpanded] = useState(
    expandedState ? expandedState : false
  );
  // Estado para controlar si el popup de Dislike se abre o no
  const [showDislikePopup, setShowDislikePopup] = useState(false);
  // Estado para el texto de feedback
  const [feedbackText, setFeedbackText] = useState('');
  // Estado para habilitar o deshabilitar el botón de enviar
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  // Estado para controlar si se ha enviado un comentario o si se ha dado like
  const [feedbackSent, setFeedbackSent] = useState(false);
  // Estado para controlar si se ha dado like
  const [liked, setLiked] = useState(false);
  // Estado para almacenar la info del artículo relacionado
  const [relatedArticle, setRelatedArticle] = useState({
    title: '',
    answerText: '',
  });
  //Estado para abrir el popup del botón Contacta conmigo
  const [contactMePopup, setContactMePopup] = useState(false);

  useEffect(() => {
    const fetchRelatedArticle = async () => {
      try {
        const relatedContent = await fetchRelatedContent(articleId);
        if (relatedContent && relatedContent.length > 0) {
          const relatedArticlesArray = relatedContent.map((item) => ({
            title: item.title,
            answerText: item.attributes.ANSWER_TEXT,
            id: item.id,
            clickCode: item.tracking.clickCode,
            rateCode: item.tracking.rateCode,
            contactMe: item.attributes.CONTACT_MANAGER,
          }));

          setRelatedArticle(relatedArticlesArray);
        }
      } catch (error) {
        console.log('Error en el fetch de Contenido Relacionado', error);
      }
    };
    fetchRelatedArticle();
  }, [articleId]);

  // Variable para manejar la visibilidad del 'Contacta conmigo' en base a si el artículo de inbenta lo permite.
  const displayContactMe = contactMe ? contactMe === 'YES' : false;

  // Función para alternar la expansión del artículo
  const handleToggle = async () => {
    setIsExpanded(!isExpanded);
    // Si el artículo se cierra, ocultar el mensaje de agradecimiento
    if (!isExpanded) {
      setFeedbackSent(false);
      await trackingCaptureEvent('click', clickCode, userInfoSSFF.custom03);
    }
  };

  // Abre el popup cuando el usuario pulsa en el botón de dislike
  const handleDislikeClick = () => {
    setShowDislikePopup(true);
  };

  // Cierra el popup cuando el usuario pulsa en la X del popup
  const handleCloseDislikePopup = () => {
    setShowDislikePopup(false);
  };

  // Controla si el textarea tiene texto para habilitar o deshabilitar el botón de "Enviar Feedback"
  const handleFeedbackTextChange = (event) => {
    const text = event.target.value;
    setFeedbackText(text);

    // Habilita o deshabilita el botón y cambia el color de fondo según si hay texto en el textarea
    setIsSubmitEnabled(text.length > 0);
  };

  // Cierra el popup con la opinión del usuario y muestra "Gracias por tu comentario"
  const handleSubmitFeedback = async () => {
    // Aquí puedes enviar el feedback o realizar cualquier otra acción deseada
    await trackingCaptureRateEvent(
      'rate',
      rateCode,
      feedbackText,
      2,
      userInfoSSFF.custom03
    );
    setShowDislikePopup(false);
    setFeedbackText('');
    setFeedbackSent(true); // Cambia el estado para indicar que se ha enviado un comentario
  };

  const handleContactMe = async () => {
    setContactMePopup(true);

    const dataUserInbenta = await isUserExist(userInfoSSFF.username);
    const message = `
    <div bgcolor="transparent">
    <div><br /></div>
    1. EMAIL CORPORATIVO
    <br />
    <b>${userInfoSSFF.username}</b>
    <div><br /></div>
    2. NOMBRE Y APELLIDOS
    <br />
    <b>${dataUserInbenta.data[0].name}</b>
    <div><br /></div>
    3. JURIDICA
    <br />
    <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
    <div><br /></div>
    4. DIVISIÓN
    <br />
    <b>${userInfoSSFF.division}</b>
    <div><br /></div>
    5. AREA
    <br />
    <b> ${userInfoSSFF.custom02} </b>
    <div><br /></div>
    6.DEPARTAMENTO
    <br />
    <b>${userInfoSSFF.department}</b>
    <div><br /></div>
    7.FUENTE
    <br />
    <b>${title}</b>
    <div><br /></div>
    </div>`;
    await createTicket(
      'SOPORTE MANAGER',
      message,
      userInfoSSFF.custom03,
      49,
      [],
      dataUserInbenta.data[0].id
    );
  };

  const handleCloseFeedbackPopup = () => {
    setContactMePopup(false);
  };

  // Maneja el evento de dar like y muestra "Gracias por tu comentario"
  const handleLikeClick = async () => {
    setLiked(true);
    await trackingCaptureRateEvent(
      'rate',
      rateCode,
      '',
      1,
      userInfoSSFF.custom03
    );
    setFeedbackSent(true);
  };

  return (
    <>
      <article className={`articleItem ${isExpanded ? 'expanded' : ''}`}>
        <div
          onClick={handleToggle}
          className={`articleItem__firstSection ${
            isExpanded ? 'expanded' : ''
          }`}
        >
          <p className="articleItem__title">{title}</p>
          <div className="expandIconBox">
            <img
              src={plus}
              alt="plus-symbol"
              className={`articleItem__plus ${isExpanded ? 'expanded' : ''}`}
            />
          </div>
          {isExpanded && (
            <img
              src={minus}
              alt="minus-symbol"
              className={`articleItem__minus ${isExpanded ? 'expanded' : ''}`}
            />
          )}
        </div>
        {isExpanded && (
          <>
            <div className="answerBox">
              <p
                className="subcategories__articleList--article__description"
                dangerouslySetInnerHTML={{ __html: answer }}
              ></p>
            </div>
          </>
        )}
        {isExpanded && articleRoutes[articleId] && (
          <div className="linkButtonContainer">
            {Array.isArray(articleRoutes[articleId]) ? (
              articleRoutes[articleId].map((route, index) => (
                <Link to={route} key={index} className="linkButton">
                  Ir al formulario {index + 1}
                </Link>
              ))
            ) : (
              <Link to={articleRoutes[articleId]} className="linkButton">
                Ir al formulario
              </Link>
            )}
          </div>
        )}
        {isExpanded && relatedArticle.length > 0 && (
          <div className="answerBox">
            {/* Contenido Relacionado */}
            <h4 className="relatedArticleItem__containerTitle">
              Artículos relacionados
            </h4>
          </div>
        )}
        {isExpanded &&
          relatedArticle.length > 0 &&
          relatedArticle.map((article) => (
            <div className="relatedArticles__container">
              <RelatedContentItem
                key={article.id}
                title={article.title}
                answer={article.answerText}
                expandedState={false}
                clickCode={article.clickCode}
                rateCode={article.rateCode}
                userInfoSSFF={userInfoSSFF}
                contactMe={article.contactMe}
              ></RelatedContentItem>
            </div>
          ))}
        {/* </div> */}

        {isExpanded && !feedbackSent && displayContactMe ? ( // Mostrar el footer solo si no se ha enviado un comentario
          <div className="contactUsContainer">
            <p className="contactUsContainer__text">
              Si necesitas más información o asistencia sobre este tema, pulsa
              el siguiente botón
            </p>
            <button
              className="contactUsContainer__button"
              onClick={handleContactMe}
            >
              Contacta conmigo
            </button>
          </div>
        ) : null}
        {isExpanded && !feedbackSent ? (
          <div className="footerBox">
            <p className="articleList--article__useful">
              ¿Te ha resultado útil esta información?
            </p>
            <div className="iconBox">
              <img
                src={like}
                alt="dislike-icon"
                className={`subcategories__articleList--article__dislike ${
                  liked ? 'hidden' : ''
                }`}
                onClick={handleDislikeClick}
              />
              {showDislikePopup && (
                <div className="dislikePopup-overlay">
                  <div className="dislikePopup-content">
                    <div className="dislikePopup__sectionTitle">
                      <h1 className="dislikePopup__sectionTitle--title">
                        ¿Por qué no es correcta esta información?
                      </h1>
                      <img
                        src={close}
                        alt="close-cross-icon"
                        className="dislikePopup__sectionTitle--closeIcon"
                        onClick={handleCloseDislikePopup}
                      />
                    </div>
                    <div className="dislikePopup__sectionText">
                      <textarea
                        className="dislikePopup__sectionText--text"
                        placeholder="Cuantos más detalles proporciones, más útil podremos hacer esta información para ti"
                        value={feedbackText}
                        onChange={handleFeedbackTextChange}
                      ></textarea>
                    </div>
                    <div className="dislikePopup__sectionButtons">
                      <button
                        className={`dislikePopup__sectionButtons--button ${
                          isSubmitEnabled ? 'enabled' : ''
                        }`}
                        onClick={handleSubmitFeedback}
                        disabled={!isSubmitEnabled}
                      >
                        Enviar feedback
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <img
                src={like}
                alt="like-icon"
                className={`subcategories__articleList--article__like ${
                  liked ? 'hidden' : ''
                }`}
                onClick={handleLikeClick}
              />
            </div>
          </div>
        ) : null}
        {isExpanded &&
          feedbackSent && ( // Mostrar "Gracias por tu comentario" si se ha enviado un comentario o se ha dado like
            <div className="footerBox">
              <p className="articleList--article__useful">
                Gracias por tu comentario
              </p>
            </div>
          )}
        {contactMePopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </article>
    </>
  );
};

export default ArticleItem;
