import React, { useState, useEffect, useRef } from 'react';
import "../styles/certificateComponent.css";
import * as pdfjsLib from 'pdfjs-dist';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCertificate } from "../../services/apiCertificate";


// Asegúrate de que la URL del worker tenga el protocolo completo
pdfjsLib.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.js';


const CertificateComponent = ({ userInfoSSFF }) => {
  const [certificate, setCertificate] = useState(null);
  const [antiguedad, setAntiguedad] = useState(false);
  const [funcion, setFuncion] = useState(false);
  const [salario, setSalario] = useState(false);
  const [jornada, setJornada] = useState(false);
  const [checked, setChecked] = useState(false);
  

  useEffect(() => {
    setChecked(antiguedad || funcion || salario || jornada);
  }, [antiguedad, funcion, salario, jornada]);


  const handleGetCertificate = async () => {
    const userInfo = {
        antiguedad,
        funcion,
        salario,
        jornada
    };

    try {
      const token = localStorage.getItem('idToken'); 
  
      const certData = await getCertificate(userInfo, token);
      if (certData.certificate && certData.certificate.file) {
        setCertificate(certData.certificate.file);
      } else {
        toast.error('No se encontró un certificado para este usuario.');
      }
    } catch (e) {
      toast.error('Error al obtener el certificado: ' + e.message);
      console.log(e.message);
    }
  };

 

  const downloadPdf = () => {
    if (certificate) {
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${certificate}`;
      link.download = 'certificado.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className='myFolderSection_certificateOptions'>
      <div className="checkbox-list">
      <div>
          <label>
            <input type="checkbox" checked={antiguedad} onChange={(e) => setAntiguedad(e.target.checked)} />
            <span>Antigüedad</span>
          </label> 
        </div>
        <div>
          <label> 
            <input type="checkbox" checked={funcion} onChange={(e) => setFuncion(e.target.checked)} />
            <span>Puesto</span>
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" checked={salario} onChange={(e) => setSalario(e.target.checked)} />
            <spam>Salario</spam>
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" checked={jornada} onChange={(e) => setJornada(e.target.checked)} />
            <spam>Jornada</spam>
          </label>
        </div>
        <button className='MyFolderSection_getCertificate' onClick={handleGetCertificate} disabled={!checked}>Previsualizar</button>
        {certificate && <button className='MyFolderSection_getCertificateDownload' onClick={downloadPdf}>Descargar</button>}
      </div>
      <ToastContainer className='toast-container' />
      <div className='MyFolderSection_message'>Si no enuentras el certificado que estás buscando, puedes solicitárnoslo a través del <a href="mailto:preguntanos@personas.telefonica.com" class="email">Portal del Empleado</a> </div>
      <div className='MyFolderSection_getCertificatePDF'>
        {certificate && <iframe title="PDF Viewer" className='MyFolderSection_certificatePDF' src={`data:application/pdf;base64,${certificate}`} />}
      </div>
    </div>
  );
};

export default CertificateComponent;

