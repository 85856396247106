import React, { useState, useEffect } from 'react';
import '../styles/payrollComponent.css';

const PayrollComponent = ({ userInfoSSFF }) => {
  const [selectedYear, setSelectedYear] = useState('');

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2000; year--) {
    years.push(year);
  }

  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const invoices = [
    { id: 1, name: 'Factura Enero 2024', date: '2024-01-01' },
    { id: 2, name: 'Factura Febrero 2023', date: '2023-02-01' },
    { id: 3, name: 'Factura Marzo 2022', date: '2022-03-01' },
    { id: 4, name: 'Factura Abril 2021', date: '2021-04-01' },
    { id: 5, name: 'Factura Mayo 2024', date: '2024-05-01' },
  ];

  const filteredInvoices = selectedYear
    ? invoices.filter((invoice) => {
        const invoiceYear = new Date(invoice.date).getFullYear();
        return invoiceYear.toString() === selectedYear;
      })
    : invoices;

  useEffect(() => {
    const resetCheckedItems = {};
    invoices.forEach((invoice) => {
      resetCheckedItems[invoice.id] = false;
    });
    setCheckedItems(resetCheckedItems);
    setSelectAll(false);
  }, [selectedYear]);

  const handleSelectAll = () => {
    const newCheckedItems = { ...checkedItems };
    filteredInvoices.forEach((invoice) => {
      newCheckedItems[invoice.id] = !selectAll;
    });
    setSelectAll(!selectAll);
    setCheckedItems(newCheckedItems);
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems({
      ...checkedItems,
      [id]: !checkedItems[id],
    });
  };

  const downloadPayroll = () => {
    const selectedInvoices = filteredInvoices.filter(
      (invoice) => checkedItems[invoice.id]
    );
    selectedInvoices.forEach((invoice) => {});
  };

  return (
    <section className="payroll_section">
      <div className="payroll_actions">
        <button className="payroll_actions-download" onClick={downloadPayroll}>
          Descargar
        </button>
        <div className="payroll_actions-dateFilter">
          <select
            className="select-button"
            onChange={handleYearChange}
            value={selectedYear}
          >
            <option className="select" value="">
              Año
            </option>
            {years.map((year) => (
              <option key={year} className="select" value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="payroll_container">
        <table className="invoice-table">
          <thead>
            <tr className="table-header">
              <th className="checkbox">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    filteredInvoices.length > 0 &&
                    filteredInvoices.every(
                      (invoice) => checkedItems[invoice.id]
                    )
                  }
                />
              </th>
              <th className="table-header_name">Nombre de documento</th>
              <th className="table-header_date">Fecha</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="checkbox">
                  <input
                    type="checkbox"
                    checked={checkedItems[invoice.id] || false}
                    onChange={() => handleCheckboxChange(invoice.id)}
                  />
                </td>
                <td className="name">{invoice.name}</td>
                <td className="date">{invoice.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PayrollComponent;
