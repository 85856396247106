import React, { useEffect } from 'react';
import Login from './components/log-in';
import { useNavigate } from 'react-router-dom';
const AuthTeamsOrMsal = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isInTeams) {
      console.debug(
        'REDIRECT: In Teams; sending home without interactive login'
      );
      navigate('/home'); // Will load ProtectedRoutes to handle auth loading
    } else {
      console.log('Not in Teams. User must login');
    }
  }, [navigate, props.isInTeams]);
  return (
    <div>
      {props.isInTeams ? (
        <>
          <p>Logged into Teams. No need to authenticate with MSAL</p>
        </>
      ) : (
        <Login setUserInfoSSFF={props.setUserInfoSSFF} />
      )}
    </div>
  );
};
export default AuthTeamsOrMsal;
