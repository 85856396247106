export const getUserInfoSSFF = async () => {
  const idToken = localStorage.getItem('idToken');


  if (!idToken) {
    throw new Error('No se encontró el idToken en localStorage');
  }

  try {
    const response = await fetch('/user', {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${idToken}`, 
      },
    });


    if (!response.ok) {
      console.error('Response not OK');
      throw new Error('Fallo en la solicitud de usuario');
    }

    const responseUserData = await response.json();
    return responseUserData;
  } catch (error) {
    throw new Error('Error en la solicitud de usuario ' + error.message);
  }
};
