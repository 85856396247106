
export const readFileAsBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64Result = reader.result.split(',')[1];
      resolve(base64Result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const ALLOWED_TYPE_FILES = ['doc', 'pdf', 'docx', 'jpg', 'jpeg', 'png'];