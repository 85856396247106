import React, { useState } from 'react';
import '../styles/loginComponent.css';
import { useNavigate } from 'react-router-dom';
import { getUserInfoSSFF } from '../services/apiSSFF';
import {
  sessionUserStartInbenta,
  USER_TYPE_TO_INBENTA_ID,
} from '../services/api';

const LoginComponent = ({ setUserInfoSSFF }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    const userSSFF = await getUserInfoSSFF(selectedUser);

  if (
    userSSFF.userInformation.d.results.length !== 0 &&
    USER_TYPE_TO_INBENTA_ID[userSSFF.userInformation.d.results[0].custom03] !== undefined
  ) {
    setUserInfoSSFF(userSSFF.userInformation.d.results[0]);
    sessionUserStartInbenta(
      userSSFF.userInformation.d.results[0].custom03,
      userSSFF.userInformation.d.results[0].custom15,
      userSSFF.userInformation.d.results[0].username
    )
    navigate('/home');
  }
};


  return (
    <>
      <div>
        <h2>Selecciona user para entrar</h2>
        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value="" disabled>Selecciona un usuario</option>
          <option value="gabriel.cruzb@telefonica.com">Gabriel Cruz / No Manager</option>
          <option value="nayra.romerosalas@telefonica.com">Nayra Romero / Manager</option>
        </select>
        <button onClick={() => handleLogin()}>Iniciar sesión</button>
      </div>
    </>
  );
};

export default LoginComponent;
